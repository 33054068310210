<div class="filter-section" [ngClass]="isFilter || isSearch ? 'mb-30' :''">
	<div *ngIf="isClose" class="close-div  pointer p-5" (click)="close()">
		<img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
	</div>
	<div class="searchBy" *ngIf="isSearch">
		<ion-input placeholder="Search By Name, Req.ID">
			<img alt="Search Icon" class="search-icon" src="/assets/icon/search.svg">
		</ion-input>
	</div>
	<div class="filter-btn" *ngIf="isFilter">
		<button class="fiter-btn w-130 align-center">
			<img alt="Filter Icon" src="/assets/icon/filter.svg">
			<div class="fs-16 fw-500 ml-10 "> Filter</div>
		</button>
	</div>
</div>
<div class="h-40 mb-10" *ngIf="pageType=='manage_product' && subCategorySelected">

	<div *ngIf="pageType=='manage_product' && allSelected">
		<span class="align-center align-end">
			<span class="selected-prod-blue align-center mr-25">
				<ion-badge *ngIf="selectedCount" slot="end" class="mr-15">{{selectedCount}}</ion-badge>
				Products Selected
			</span>
			<div class="align-baseline">
				<div>
					<ion-toggle [(ngModel)]="toggleValue" [disabled]="isToggleDisable"
						(click)="selectAllProductToogle()" slot="end" class=" mb-4" labelPlacement="end">
					</ion-toggle>
				</div>
				<div class="fs-16 fw-600 ml-10 ">{{toggleValue?'Disable':'Enable'}} All The
					Products</div>
			</div>
		</span>
	</div>

	<div class="mb-15 d-flex"
		*ngIf="(pageType=='manage_product' && subCategorySelected) && (selectedCount > 0 && selectedCount < listOfAllData.length)">

		<span class="selected-prod-blue align-center mr-25">
			<ion-badge *ngIf="selectedCount" slot="end" class="mr-15">{{selectedCount}}</ion-badge>
			Products Selected
		</span>

		<button class="enable-btn" [disabled]="isToggleDisable || enableButtonDisabled"
			(click)="toggleProductEnableOrDisable('enable')">
			Enable
		</button>
		<button [disabled]="isToggleDisable || disableButtonDisabled" class=" ml-10 disable-btn"
			(click)="toggleProductEnableOrDisable('disable')">
			Disable
		</button>
	</div>
</div>


<div class="section-content pd-t-30  mt-" #tableContainer id="tableContainer"
	[ngClass]="[ tableHeight ? 'modalBox' : '', checkoutAddress ? 'checkout-address-table' : '',subCategorySelected ? 'sub-category-selected-table':'']">
	<div class="listing-table" [ngClass]="[ checkoutAddress ? 'checkout-address-border' : '']">
		<table class="table">
			<thead
				*ngIf="getPlatformService.isDesktop || (listOfAllData && listOfAllData.length > 0 && !getPlatformService.isDesktop)"
				[ngClass]="[ checkoutAddress ? 'checkout-address-thead' : '']">
				<ng-container *ngFor="let map of columnMaps;let i = index;">
					<th [style.min-width]="widthConfig[i]" class="column-heading text-left" [ngClass]=" isStickyColumn && i === ( columnMaps.length -1) ? 'sticky-header text-center' :
                         isStickyStatusActionColumn && i === ( columnMaps.length -2) ? 'sticky-header' :
                          isStickyStatusActionColumn && i === ( columnMaps.length -1) ? 'sticky-header-2': null "
						[style.right]="isStickyColumn && i === ( columnMaps.length -1) ? '-1px' : isStickyStatusActionColumn && i === ( columnMaps.length -2) ? widthConfig[i+1] :
                        isStickyStatusActionColumn && i === ( columnMaps.length -1) ? '0px' : 'unset'">
						<div class="table-head">
							<div>
								<ng-container
									*ngIf="pageType=='manage_product' && map.header=='#'  && subCategorySelected && listOfAllData.length > 0">
									<ion-checkbox class="mr-15" [disabled]="isToggleDisable"
										(ngModelChange)="selectAll()" [(ngModel)]="allSelected"></ion-checkbox>
								</ng-container>
								{{ map.header == 'selection' ? '' : map.header }}
								<p *ngIf="map.other_text" class="other-text-lbl">{{map.other_text}}</p>
							</div>
							<ng-container
								*ngIf="commonService.userData.isSeller && map.header != 'Action' && map.header != 'Dispatch location' && map.header != 'Live Status' &&
                            map.header != 'Shipping address' && map.header != 'User rights' && map.header !='Payment type'
                            && map.header !='District type' && map.header !='Total area covered' && map.header !='#' &&map.header !='Admin role' && map.header!='Status' && map.header!='min_capacity' && map.header!='Min Capacity' && map.header!='Max Capacity' && map.header !='Vehicle Reg #' && map.header !='Order id' && map.header !='Received on' && map.header !='Payment status' && map.header !='Earning amount' && map.header !='Quality check confirmed on' && map.header!='Package' && map.header !='Categories' && map.header!='Order status'">
								<div class="sort-icon-container">
									<div class="sort-icon icon-up">
										<img src="assets/icon/small-arrow.svg" (click)="sortBy(map,'asc')" alt="up"
											class="icon">
									</div>
									<div class="sort-icon icon-down">
										<img src="assets/icon/small-arrow.svg" (click)="sortBy(map,'desc')" alt="down"
											class="icon">
									</div>
								</div>
							</ng-container>
						</div>
					</th>
				</ng-container>
			</thead>

			<tbody class="table-body" *ngIf="listOfAllData && listOfAllData.length > 0 && !isLoading">
				<tr *ngFor="let record of listOfAllData;let i = index;"
					[ngClass]="[i !==( listOfAllData.length - 1 ) ? 'table-row' : '']">
					<ng-container *ngFor="let map of columnMaps;let j = index;">
						<ng-container
							*ngIf="map['action'] === undefined &&  map['toggle'] === undefined
                           &&  map['payment_status'] === undefined  &&  map['info_status'] === undefined && map['expired_after'] === undefined">

							<td [style.min-width]="widthConfig[j]" [ngClass]="map['image'] ? 'display-flex' : ''">
								<ng-container *ngIf="map['image']">
									<img alt="Image" class="product-img mr-5" [src]="record['img']">
								</ng-container>
								<ng-container *ngIf="!map['status']">
									<div [title]="record[map.primaryKey]" class="text-overflow" *ngIf="map['header'] != '#' && map['header'] != 'selection' && !map['payment_status'] && !map['is_price'] && !map['is_qty_price']
                                     && map['header'] != 'QC Confirmed On' && map['header'] != 'Earning Amount'"
										[ngClass]="[ map['primaryKey'] == 'user_rights' ? 'rights-data' : '']">

										{{record[map.primaryKey] ===undefined|| record[map.primaryKey] === null
										|| record[map.primaryKey] === '' ? '-' : record[map.primaryKey] }}
									</div>

									<span class="mr-15"
										*ngIf="pageType=='manage_product' && map.header=='#' && subCategorySelected">
										<ion-checkbox [(ngModel)]="record.selected" [disabled]="isToggleDisable"
											(ngModelChange)="toggleCheckbox()"></ion-checkbox>
									</span>

									<span *ngIf="map['header'] == '#'">
										{{ record['index'] }}
									</span>
									<div *ngIf="map['header'] == 'QC Confirmed On'">
										{{record[map.primaryKey] ===undefined|| record[map.primaryKey] === null
										|| record[map.primaryKey] === '' ? '-' : record[map.primaryKey] }}
									</div>
									<div *ngIf="map['header'] == 'Earning Amount'">
										{{commonService.currencySymbol}} {{record[map.primaryKey] ===undefined||
										record[map.primaryKey] === null
										|| record[map.primaryKey] === '' ? '-' : record[map.primaryKey] }}
									</div>
									<div *ngIf="map['is_qty_price'] == true">
										{{record[map.primaryKey] ===undefined||
										record[map.primaryKey] === null
										|| record[map.primaryKey] === '' ? '-' : (record[map.primaryKey] |
										indianCurrency)}}
										X
										{{commonService.currencySymbol}} {{record[map.secondaryKey] ===undefined||
										record[map.secondaryKey] === null
										|| record[map.secondaryKey] === '' ? '-' : (record[map.secondaryKey] )}}
									</div>
									<div *ngIf="map['is_price'] == true">
										{{commonService.currencySymbol}} {{record[map.primaryKey] ===undefined||
										record[map.primaryKey] === null
										|| record[map.primaryKey] === '' ? '0' :
										(record[map.primaryKey]|indianCurrency)}}
									</div>
									<div *ngIf="map['header'] == 'selection'">
										<input [(ngModel)]="addresCheck" [name]="'radio-group'"
											[id]="record['address_id']" [value]="record['address_id']" type="radio" />
										<label [for]="record['address_id']"></label>
									</div>
								</ng-container>
								<ng-container *ngIf="map['status']">
									<div [class]="record['status_type']">{{record[map.primaryKey]}}
									</div>
								</ng-container>

							</td>
						</ng-container>
						<!-- pooja code for my order -->
						<ng-container *ngIf="map['payment_status'] === true">
							<td>
								<div class="align-center">
									<span [ngClass]="record['payment_status_type'] == 'success'? 'green-light-box' :
                                record['payment_status_type'] == 'fail'? 'red-light-box' :
                                record['payment_status_type'] == 'warning'? 'orange-light-box' :
                                record['payment_status_type'] == 'wait'? 'grey-light-box' :
                                record['payment_status_type'] == 'early'? 'blue-light-box' :'red-light-text' ">
										{{record[map.primaryKey]}}
									</span>
									<span class="ml-10 fw-600" *ngIf="record['payment_days_left']">
										{{record['payment_days_left']}}
									</span>
								</div>
							</td>

						</ng-container>
						<ng-container *ngIf="map['info_status'] === true">
							<td [style.min-width]="widthConfig[j]" [style.right]="widthConfig[j+1]"
								[ngClass]="isStickyStatusActionColumn ? 'sticky-column' : ''">
								<div class="display-flex">
									<span *ngIf="map.primaryKey == 'product_status'" [ngClass]="record['status_type2'] == 'success'? 'green-light-box' :
                                        record['status_type2'] == 'fail'? 'red-light-box' :'orange-light-box' ">
										{{record[map.primaryKey]}}
									</span>
									<span *ngIf="map.primaryKey != 'product_status'" [ngClass]="
                                        record['status_type'] == 'wait' ? 'grey-light-box' :
                                        record['status_type'] == 'success'? 'green-light-box' :
                                        record['status_type'] == 'fail'? 'red-light-box' :
                                         record['status_type'] == 'early'? 'blue-light-box' :'orange-light-box' ">
										{{record[map.primaryKey]}}
									</span>
									<div *ngIf="record[map.primaryKey] == 'arrived'"
										class="error-text fw-b ml-10 w-100 mt-5 ">
										{{
										this['remainingTime' + record['order_item_id']] }}</div>
								</div>
							</td>
						</ng-container>

						<ng-container *ngIf="map['toggle'] === true ">
							<td>
								<div class="display-flex">
									<span class="toggle-span">
										<ion-toggle (click)="adminToggle(record)"
											[(ngModel)]="record[map.primaryKey]"></ion-toggle>
									</span>
									<span class="orange-label ml-10">Admin </span>
								</div>
							</td>
						</ng-container>

						<ng-container *ngIf="map['action'] === true ">
							<td [style.min-width]="widthConfig[j]"
								[ngClass]="[ isStickyColumn ? 'sticky-column text-center' : isStickyStatusActionColumn ? 'sticky-column-2' : '', record.type == 'PLACE_ORDER' ? '' : '']">
								<ng-container *ngIf="record.updateToggle">
									<span class="toggle-span">
										<ion-toggle [disabled]="isToggleDisable" (click)="userToggle(record)"
											[(ngModel)]="record['isActive']" slot="end" class="mr-20 mb-4"></ion-toggle>
									</span>
								</ng-container>
								<ng-container *ngIf="record.edit">
									<span class="tooltip mr-20 pointer" data-tooltip="Edit" (click)="editData(record)">
										<img alt="Edit Icon" class="tooltip" data-tooltip="Edit"
											src="./assets/icon/edit.svg" class="pointer">
									</span>
								</ng-container>
								<ng-container *ngIf="record.redirect">
									<span (click)="editData(record)" class="redirectlinks">{{record.redirect_name}}<span
											class="redirectlinks-image" class="ml-5">
											<img alt="Right Arrow" src="./assets/icon/small-right-arrow.svg">
										</span>
									</span>
								</ng-container>
								<ng-container *ngIf="record.delete">
									<span class="tooltip mr-20 pointer" data-tooltip="Delete"
										(click)="deleteConfrm(record, i)">
										<img alt="Images" src="./assets/icon/delete.svg" class=" pointer"></span>
								</ng-container>

								<ng-container *ngIf="record.view">
									<span class="tooltip" data-tooltip="View">
										<img alt="View Icon" class="pointer mr-15"
											(click)="viewDetail(record.viewLink,record)"
											src="./assets/icon/view.svg"></span>
								</ng-container>

								<ng-container *ngIf="record.utr_NumberIcon">
									<span class="tooltip pointer" data-tooltip="Make Payment"> <img alt="UTR Icon"
											src="./assets/icon/utr.svg" class="mr-15 pointer"
											(click)="makePayment(record)"></span>
								</ng-container>


								<ng-container *ngIf="record.accept_Icon">
									<span class="tooltip accpetCheck" data-tooltip="Accept"> <img alt="Check Icon"
											class=" mr-15 pointer" (click)="acceptRejectOrderRequest(record,'accept')"
											src="./assets/icon/checkIcon.png"></span>
								</ng-container>
								<ng-container *ngIf="record.reject_Icon">
									<span class="tooltip" data-tooltip="Reject"> <img alt="Image"
											src="./assets/icon/close.svg"
											(click)="acceptRejectOrderRequest(record,'reject')"
											class="mr-15 pointer"></span>
								</ng-container>
								<ng-container *ngIf="record.download_Icon && record.invoice_url">
									<span class="tooltip" data-tooltip="View invoice">
										<a [href]="record.invoice_url" download target="_blank">
											<img alt="Image" class="pointer invoice-icon"
												[ngClass]="record.reject_Icon && record.accept_Icon ? 'ml-20':''"
												src="./assets/svg/preview-invoice.svg">
										</a>
									</span>
								</ng-container>
								<ng-container *ngIf="record.isCustomBtn">
									<button *ngIf="record.isCustomBtnText != 'View Order'"
										(click)="customBtnClick(record)" class="submit-btn w-156 ml-20"
										[ngClass]="record.isWhiteBtn ? 'clear-btn' : 'submit-btn'">
										{{record.isCustomBtnText}}
									</button>
									<button *ngIf="record.isCustomBtnText == 'View Order'"
										(click)="viewDetail(record.viewDetailLink,record)"
										class="submit-btn w-156 ml-20"
										[ngClass]="record.isWhiteBtn ? 'clear-btn' : 'submit-btn'">
										{{record.isCustomBtnText}}
									</button>
								</ng-container>
								<ng-container *ngIf="record.type == 'PLACE_ORDER'">
									<button class="clear-btn w-88 ml-15" (click)="updateQuote(record)">
										{{record.isReBidBtnText }}
									</button>
								</ng-container>
								<ng-container *ngIf="record.isCustomText">
									<span class="error-text ml-20 fw-b">{{record.isCustomText}}</span>
								</ng-container>

							</td>
						</ng-container>

					</ng-container>
				</tr>
			</tbody>
			<!-- ---------------- No record ----------------- -->
			<tbody class="table-body"
				*ngIf="listOfAllData && listOfAllData.length < 1 && getPlatformService.isDesktop && !isLoading ">
				<tr>
					<td colspan="6" class="no-records">
						<div class="no-data">
							No Records
						</div>
					</td>
				</tr>
			</tbody>

			<tbody class="table-body"
				*ngIf="listOfAllData && listOfAllData.length < 1 && !getPlatformService.isDesktop && !isLoading">
				<tr>
					<td class="no-records">
						<div class="no-data">
							No Records
						</div>
					</td>
				</tr>
			</tbody>


			<!-- ---------------- Loading View ----------------- -->
			<tbody class="table-body" *ngIf="(isLoading && getPlatformService.isDesktop )">
				<tr>
					<td colspan="6" class="no-records">
						<div class="no-data">
							<app-loader></app-loader>
						</div>
					</td>
				</tr>
			</tbody>

			<tbody class="table-body" *ngIf="(isLoading && !getPlatformService.isDesktop)">
				<tr>
					<td class="no-records">
						<div class="no-data">
							<app-loader></app-loader>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</div>

<div *ngIf="isBtn" class="form-button mt-30 ml-5 right">
	<button type="primary" class="w-147 submit-btn" (click)="close()">
		Continue
	</button>
</div>