import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { AuthenticationService } from './../../services/authentication.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-account-menu',
	templateUrl: './account-menu.component.html',
	styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
	public subscription = new Subscription();
	isEnterprise: boolean = false;
	profileEnum = profileTypeEnum;
	isLoading = false;
	@Input() isMobileSideMenu: boolean = false;
	profileType = '';
	isLogin: boolean = false;
	user_type: string = '';
	customer_type: string = '';
	profileImage: any;
	seller_type: string = '';
	name: any;
	menuOptions = [];
	isCurrentProfile: string;
	constructor(
		public commonService: CommonService,
		private navCtrl: NavController,
		private alertController: AlertController,
		private authService: AuthenticationService,
		private router: Router,
		private toasterService: ToasterService,
		private dataService: ApiService

	) {
		this.commonService.userData = JSON.parse(this.commonService.localStorageGet('info'));
		this.commonService.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
		if (this.commonService.isLoggedIn) {
			this.getSideMenu();
			this.commonService.getUserDetail().then((data) => {
				this.profileImage = data['profile_picture'] ? data['profile_picture'] : null;
			})
		}
		this.subscription = this.commonService.profilePictureUpdated.subscribe(data => {
			if (data) {
				this.profileImage = data.user_detail.profile_picture;
			}
		});

		this.subscription = this.commonService.menuData.subscribe(res => {
			setTimeout(() => {
				if (this.commonService.isLoggedIn) {
					this.getSideMenu();
				}
			}, 200);
		})
		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == 'userResponseInfo') this.commonService.userData = data['data'] ? data['data'] : {};
			setTimeout(() => {
				if (data.event == 'sidemenuChnage') {
					this.getSideMenu();
				}
			}, 300);
		})

	}

	ngOnInit() {
		if (this.commonService.isLoggedIn) {
			this.commonService.getUserDetail().then((data) => {
				setTimeout(() => {
					this.commonService.userData.name = data['first_name'] + ' ' + data['last_name'];
					this.profileImage = data['profile_picture'] ? data['profile_picture'] : null;
				}, 1000);
			})


		}
	}

	sidemenuChange(link) {
		this.navCtrl.navigateForward(link, { animated: false });
	}



	getSideMenu(isDataCalled?) {
		if (isDataCalled) {
			return;
		}
		let params = {
			"user_type": this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller,
			"menu_for": this.commonService.userData['user_profile']
		}
		this.menuOptions = [
			{
				menu_name: "My Profile",
				link: "/account-details/my-profile"
			},
			{
				menu_name: "Log-Out",
				link: ""
			}
		]
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.accountMenu, params).subscribe(data => {
				this.isLoading = false;
				let menuOptions = data['data'];
				menuOptions.forEach(element => {
					element.link = element.link.replace('/home', '');
				});

				let usertype = this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller;

				this.menuOptions = [...menuOptions];
			}, (error: any) => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	async logoutAlert() {
		const alert = await this.alertController.create({
			header: 'Are you sure ? ',
			message: 'You want to logout ?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.logout();
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	logout() {
		this.commonService.logout();
	}

	userSwitch(profile) {
		let params = {
			"user_type": profile && !profile.isSeller ? "SELLER" : "CUSTOMER"
		}
		let name = '';
		this.subscription.add(
			this.dataService.get(URLS.profileSwitch, params).subscribe(data => {
				name = data['data']['first_name'] + ' ' + data['data']['last_name']
				if (this.commonService.userData['isSeller']) {
					this.commonService.userData['isCustomer'] = true;
					this.commonService.userData['isSeller'] = false;
				}
				else if (this.commonService.userData['isCustomer']) {
					this.commonService.userData['isSeller'] = true;
					this.commonService.userData['isCustomer'] = false;
				}
				this.commonService.setEvent('sidemenuChnage', "true");
				let userResponseInfo = {
					user_type: this.commonService.userData['user_type'] ? this.commonService.userData['user_type'] : '',
					customer_type: this.commonService.userData['customer_type'] ? this.commonService.userData['customer_type'] : '',
					seller_type: this.commonService.userData['seller_type'] ? this.commonService.userData['seller_type'] : '',
					user_profile: this.commonService.userData['seller_type'] && this.commonService.userData['seller_type'] != 'NONE' ? this.commonService.userData['seller_type'] : this.commonService.userData['customer_type'],
					approval_status: this.commonService.userData['approval_status'] ? this.commonService.userData['approval_status'] : '',
					seller_approval_status: this.commonService.userData['seller_approval_status'] ? this.commonService.userData['seller_approval_status'] : '',
					isCustomer: this.commonService.userData['isCustomer'],
					isSeller: this.commonService.userData['isSeller'],
					name: name
				}
				this.commonService.updateProfileData();
				this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
				this.commonService.localStorageSet('customer_approval', data['data']['approval_status'] ? data['data']['approval_status'] : 'NONE');
				this.commonService.localStorageSet('seller_approval', data['data']['seller_approval_status'] ? data['data']['seller_approval_status'] : 'NONE');
				this.commonService.setEvent('userResponseInfo', userResponseInfo);
				this.navCtrl.navigateForward('/account-details/my-profile');
				let customNotification = {};
				if (data['code'] == 200) {
					if (data['data']['show_message']) {
						customNotification['message'] = data['message'];
						customNotification['type'] = 'success';
						this.commonService.setEvent('alertMessage', customNotification);
					}
				} else {
					customNotification['message'] = data['message'];
					customNotification['type'] = 'warning'
					this.commonService.setEvent('alertMessage', customNotification)
				}

			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	becomeAUser() {
		if (this.commonService.userData.user_type == this.profileEnum.Customer) {
			this.commonService.setEvent('isBecomeSeller', 'true');
			this.commonService.localStorageSet('isBecomeSeller', 'true');
			this.commonService.localStorageSet("becomeSeller", 'true');
			setTimeout(() => {
				this.navCtrl.navigateRoot(['/seller-register']);
			}, 200);
		}
		else if (this.commonService.userData.user_type == this.profileEnum.Seller) {
			this.commonService.setEvent('isBecomeCustomer', 'true');
			this.commonService.localStorageSet('isBecomeCustomer', 'true');
			this.commonService.localStorageSet("becomeCustomer", 'true');
			setTimeout(() => {
				this.navCtrl.navigateRoot(['/registration']);
			}, 200);
		}
	}


	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
