import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonButton, NavController, Platform } from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { profileTypeEnum } from './../../_common/enum.enum';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';
import { CategoriesComponent } from './../categories/categories.component';
import { DocumentComponent } from './../document/document.component';

@Component({
	selector: 'app-two-factor-auth',
	templateUrl: './two-factor-auth.component.html',
	styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent implements OnInit {
	@ViewChild('codeInput') codeInput !: CodeInputComponent;
	@ViewChild(CategoriesComponent) categoriesData;
	@ViewChild(DocumentComponent) documentData;

	@ViewChild(IonButton) button;
	profileEnum = profileTypeEnum;
	public subscription = new Subscription();
	public subscribeOtp = new Subscription();

	public isCodeHidden = true;
	public otpInput = null;
	public redirectPage: string = '';
	public resendTimer: any = "00:30";
	public userDetails: any;
	public encryptedUserData: any;
	public verifyEmail: boolean = false;
	public locationData: any;

	otpCount = 0;
	emailOtpCount = 0;
	registrationDetails: any;
	userName: any;
	disableBtn = true;
	isResendLoading = false;
	isLoading = false;
	uuid: string;
	isGettingOtp = false;
	mobileNo: any;
	CurrentProfile = '';
	profileType = '';
	userData: any;
	decryptedUserData: any;
	fromPage: any;
	registrationType: string;
	constructor(
		private router: Router,
		private commonService: CommonService,
		private navCtrl: NavController,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private route: ActivatedRoute,
		private platform: Platform,
		private location: Location) {

		let state = this.router.getCurrentNavigation().extras.state;
		if (state && state.locationData) {
			this.locationData = state.locationData
		}
	}

	ngOnInit() {
		this.startTimer();
		this.commonService.localStorageRemove('uuid');
		setTimeout(() => {
			this.codeInput.focusOnField(0);
		}, 600);
		this.registrationType = this.commonService.localStorageGet("registration_type");
		if (this.registrationType == 'seller') {
			const sellerDecryptedData = this.commonService.localStorageGet("seller_registration");
			this.registrationDetails = this.commonService.decryptData(sellerDecryptedData);
			this.registrationDetails = JSON.parse(this.registrationDetails);
		}
		else if (this.registrationType == 'customer') {
			const customerDecryptedData = this.commonService.localStorageGet("customer_registration");
			this.registrationDetails = this.commonService.decryptData(customerDecryptedData);
			this.registrationDetails = JSON.parse(this.registrationDetails);
		}


		this.commonService.eventObservable.subscribe((data: any) => {
			if (data.event == "profileType") {
				this.profileType = data['data'];
			}
			if (data.event == 'currentProfile') {
				this.CurrentProfile = data['data']
			}

		});
		this.route.queryParams
			.subscribe(params => {
				if (params.fromPage) {
					this.fromPage = params.fromPage
				}
			}
			);

	}

	enterOtpSubmit(event) {
		setTimeout(() => {
			if (event.keyCode === 13 && this.otpInput.length == 4) {
				this.verifyOtp();
			}
		}, 1000);
	}

	onCodeCompleted(event) {
		if (event) {
			this.otpInput = event;
		}
		if (this.otpInput.length == 4) {
			setTimeout(() => {
				if (this.button)
					this.button.el.focus();

			}, 600);
		}
	}

	public startTimer() {
		if (this.subscribeOtp) {
			this.subscribeOtp.unsubscribe();
		}
		this.subscribeOtp = new Subscription();
		this.subscribeOtp = interval(1000).pipe(take(31)).subscribe(res => {
			this.resendTimer = this.transform(30 - res);
			if (res >= 30) {
				this.resendTimer = null;
				this.subscribeOtp.unsubscribe();
			}
		});
	}

	transform(value: number): string {
		const minutes: number = Math.floor(value / 60);
		return (minutes < 10 ? '0' + minutes : minutes) + ':' + ((value - minutes * 60) < 10 ? '0' + (value - minutes * 60) : (value - minutes * 60))
	}

	generateOtp() {
		let otpParam = {}
		this.otpInput = null;
		this.codeInput.reset();
		this.userDetails = this.commonService.localStorageGet("user_details")
		this.decryptedUserData = this.commonService.decryptData(this.userDetails)
		this.userData = JSON.parse(this.decryptedUserData)
		this.isResendLoading = true;
		if (this.verifyEmail) {
			if (this.fromPage == 'registration') {
				otpParam = {
					"mobile_no": this.userData.mobile_no,
					"purpose_type": "REGISTRATION",
					"email": this.userData.email,
				}

				this.encryptedUserData = this.commonService.encryptData(JSON.stringify(otpParam))
				let emailOtpParam = {
					data: this.encryptedUserData
				}
				this.subscription.add(
					this.dataService.post(URLS.generateEmailOtp, emailOtpParam).subscribe(data => {
						if (data['code'] == 200) {
							this.toasterService.successToast(data['message'], "top");
							this.isResendLoading = false;
							this.resendTimer = "00:30"
							this.startTimer();
							this.emailOtpCount += 1;
						}
						else {
							this.toasterService.errorToast(data['message'], 'top');
							this.isResendLoading = false;
						}
					}, error => {
						this.isResendLoading = false;
						this.toasterService.errorToast(error['message'], 'top');
					})
				)
			}
		} else {
			if (this.fromPage == 'registration') {
				let userRegistrationType = this.commonService.localStorageGet('profileType');
				otpParam = {
					"mobile_no": this.userData.mobile_no,
					"purpose_type": "REGISTRATION",
					"platform": this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB",
					"email": this.userData.email,
					"user_type": this.registrationType == 'customer' ? 'CUSTOMER' : 'SELLER',
					"request_type": userRegistrationType == this.profileEnum.Enterprise ? 'ENTERPRISE' : 'INDIVIDUAL'
				}

				this.subscription.add(
					this.dataService.post(URLS.generateOtp, otpParam).subscribe(data => {
						if (data['code'] == 200) {
							let userData = data['data'];
							let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
							this.commonService.localStorageSet('user_details', encryptedUserDetails);
							if (userData) {
								userData['uuid'] = userData.uuid ? userData.uuid : ''
							}
							this.toasterService.successToast(data['message'], "top");
							this.isResendLoading = false;
							this.resendTimer = "00:30"
							this.startTimer();
							this.otpCount += 1;
						}
						else {
							this.toasterService.errorToast(data['message'], 'top');
							this.isResendLoading = false;
						}
					}, error => {
						this.isResendLoading = false;
						this.toasterService.errorToast(error['message'], 'top');
					})
				)
			}
			if (this.fromPage == 'forgotPassword') {
				let forgotPasswordDetails = this.commonService.localStorageGet('forgot_password_details');

				otpParam = {
					"user_name": forgotPasswordDetails,
					"purpose_type": "FORGOT_PASSWORD",
					"platform": this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB",

				}

				this.subscription.add(
					this.dataService.post(URLS.forgotPassword, otpParam).subscribe(data => {
						if (data['code'] == 200) {
							let userData = data['data'];
							let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
							this.commonService.localStorageSet('user_details', encryptedUserDetails);

							if (userData) {
								userData['uuid'] = userData.uuid ? userData.uuid : ''
								this.commonService.localStorageSet('uuid', userData.uuid);
								let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
								this.commonService.localStorageSet('user_details', encryptedUserDetails);
								this.commonService.localStorageSet("forgot_password_details", otpParam['user_name'])
								this.toasterService.successToast(data['message'], 'top');
							}

							this.toasterService.successToast(data['message'], "top");
							this.isResendLoading = false;
							this.resendTimer = "00:30"
							this.startTimer();
							this.otpCount += 1;
						}
						else {
							this.toasterService.errorToast(data['message'], 'top');
							this.isResendLoading = false;
						}
					}, error => {
						this.isResendLoading = false;
						this.toasterService.errorToast(error['message'], 'top');
					})
				)
			}
			if (this.fromPage == 'my-profile') {
				let profileData = this.commonService.localStorageGet("my_profile")
				this.decryptedUserData = this.commonService.decryptData(profileData);
				let profileDetails = JSON.parse(this.decryptedUserData)
				otpParam = {
					"mobile_no": profileDetails.mobile_no,
					"purpose_type": "PROFILE",
					"platform": this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB"
				}

				this.subscription.add(
					this.dataService.post(URLS.generateOtp, otpParam).subscribe(data => {
						if (data['code'] == 200) {
							let userData = data['data'];
							let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
							this.commonService.localStorageSet('user_details', encryptedUserDetails);
							if (userData) {
								userData['uuid'] = userData.uuid ? userData.uuid : ''
							}
							this.toasterService.successToast(data['message'], "top");
							this.isResendLoading = false;
							this.resendTimer = "00:30"
							this.startTimer();
							this.otpCount += 1;
						}
						else {
							this.toasterService.errorToast(data['message'], 'top');
							this.isResendLoading = false;
						}
					}, error => {
						this.isResendLoading = false;
						this.toasterService.errorToast(error['message'], 'top');
					})
				)
			}

		}
	}

	verifyOtp() {
		if (this.otpInput && this.otpInput.length == 6) {
			if (this.verifyEmail) {
				if (this.fromPage == 'registration') {
					this.userDetails = this.commonService.localStorageGet("user_details")
					this.decryptedUserData = this.commonService.decryptData(this.userDetails)
					this.userData = JSON.parse(this.decryptedUserData)
					if (this.userData) {
						delete this.userData['is_otp_generated'];
						delete this.userData['purpose_type'];
						delete this.userData['email'];
						delete this.userData['uuid'];
						this.userData['otp'] = this.otpInput
					}

					this.encryptedUserData = this.commonService.encryptData(JSON.stringify(this.userData))
					const params = {
						"data": this.encryptedUserData
					}
					this.isLoading = true;
					this.subscription.add(
						this.dataService.post(URLS.verifyEmailOtp, params).subscribe(data => {
							if (data['code'] == 200) {
								if (this.fromPage == 'registration') {
									if (this.registrationType == 'seller') {
										this.sellerRegistration();
									}
									else if (this.registrationType == 'customer') {
										this.customerRegistration();
									}
									this.commonService.localStorageSet("otpVerified", true);
								}
							}
							else {
								this.otpInput = null;
								this.codeInput.reset();
								this.toasterService.errorToast(data['message'], 'top');
								this.isLoading = false;
								this.disableBtn = true;
							}
						}, error => {
							this.otpInput = null;
							this.codeInput.reset();
							this.isLoading = false;
							this.disableBtn = true;
							this.toasterService.errorToast(error['message'], 'top');
						})
					)
				}
			}
			else {
				if (this.fromPage == 'registration') {
					this.userDetails = this.commonService.localStorageGet("user_details")
					this.decryptedUserData = this.commonService.decryptData(this.userDetails)
					this.userData = JSON.parse(this.decryptedUserData)
					if (this.userData) {
						delete this.userData['is_otp_generated']
						this.userData['otp'] = this.otpInput
						this.userData['platform'] = this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB";
					}
				}
				if (this.fromPage == 'forgotPassword') {
					let forgotPasswordDetails = this.commonService.localStorageGet('forgot_password_details');
					this.userDetails = this.commonService.localStorageGet("user_details")
					this.decryptedUserData = this.commonService.decryptData(this.userDetails)
					this.userData = JSON.parse(this.decryptedUserData)

					this.userData["user_name"] = forgotPasswordDetails;
					this.userData['otp'] = this.otpInput;
					this.userData['platform'] = this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB";


				}
				if (this.fromPage == 'my-profile') {
					this.userDetails = this.commonService.localStorageGet("my_profile")
					this.decryptedUserData = this.commonService.decryptData(this.userDetails)
					this.userData = JSON.parse(this.decryptedUserData)
					if (this.userData) {
						delete this.userData['is_otp_generated'];
						delete this.userData['reference_id'];
						delete this.userData['reference_type'];
						this.userData['otp'] = this.otpInput
						this.userData['platform'] = this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB";
					}
				}


				this.encryptedUserData = this.commonService.encryptData(JSON.stringify(this.userData))

				const params = {
					"data": this.encryptedUserData
				}
				this.isLoading = true;
				this.subscription.add(
					this.dataService.post(URLS.otpVerification, params).subscribe(data => {
						if (data['code'] == 200) {
							this.isLoading = false;
							if (this.fromPage == 'registration') {
								if (data['data']['mail_send']) {
									this.otpInput = null;
									this.codeInput.reset();
									this.resendTimer = "00:30"
									this.startTimer();
									this.verifyEmail = true;
									this.toasterService.successToast('We have sent an OTP on your Email ID', 'top');
								}
							}
							if (this.fromPage == 'forgotPassword') {
								this.commonService.localStorageSet('ForgotUserData', data['data'].userData);
								this.commonService.localStorageSet("otpVerified", true);
								this.navCtrl.navigateForward('create-new-password', { animated: false });
							}
							if (this.fromPage == 'my-profile') {
								this.updateProfile();
								this.toasterService.successToast(data['message'], "top");
							}

							this.commonService.setEvent('profileType', this.profileType);
							this.commonService.setEvent('currentProfile', this.CurrentProfile);

						}
						else {
							this.otpInput = null;
							this.codeInput.reset();
							this.toasterService.errorToast(data['message'], 'top');
						}
						this.isLoading = false;
						this.disableBtn = true;
					}, error => {
						this.otpInput = null;
						this.codeInput.reset();
						this.isLoading = false;
						this.toasterService.errorToast(error['message'], 'top');
						this.disableBtn = true;
					})
				)
			}
		}
	}



	updateProfile() {
		let enctyptedData = this.commonService.localStorageGet('my_profile_otp')
		let decryptedUserData = this.commonService.decryptData(enctyptedData)
		let myProfile = JSON.parse(decryptedUserData)
		let profileFormData = new FormData();
		if (myProfile.mobile_no) {
			profileFormData.append("mobile_no", myProfile.mobile_no);
		}
		if (myProfile.first_name) {
			profileFormData.append("first_name", myProfile.first_name);
		}
		if (myProfile.last_name) {
			profileFormData.append("last_name", myProfile.last_name);
		}
		if (myProfile.company_name) {
			profileFormData.append("company_name", myProfile.company_name);
		}
		if (myProfile.company_address) {
			profileFormData.append("company_address", myProfile.company_address);
		}
		if (this.commonService.profilePicture) {
			profileFormData.append("files", this.commonService.profilePicture);
		}
		if (myProfile.platform) {
			profileFormData.append("platform", this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB",);

		} else {
			return;
		}
		this.subscription.add(
			this.dataService.put(URLS.updateProfile, profileFormData).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						let myProfileData = data['data']
						if (myProfileData) {
							if (myProfile.last_name || myProfile.first_name) {
								let userResponseInfo = {
									user_type: this.commonService.userData['user_type'] ? this.commonService.userData['user_type'] : '',
									customer_type: this.commonService.userData['customer_type'] ? this.commonService.userData['customer_type'] : '',
									seller_type: this.commonService.userData['seller_type'] ? this.commonService.userData['seller_type'] : '',
									user_profile: this.commonService.userData['seller_type'] && this.commonService.userData['seller_type'] != 'NONE' ? this.commonService.userData['seller_type'] : this.commonService.userData['customer_type'],
									approval_status: this.commonService.userData['approval_status'] ? this.commonService.userData['approval_status'] : '',
									seller_approval_status: this.commonService.userData['seller_approval_status'] ? this.commonService.userData['seller_approval_status'] : '',
									isCustomer: this.commonService.userData['isCustomer'],
									isSeller: this.commonService.userData['isSeller'],
									name: myProfile.first_name + ' ' + myProfile.last_name,
								}
								this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
								this.commonService.setEvent('userResponseInfo', userResponseInfo);
							}
							this.commonService.scrollToTop();
							this.navCtrl.navigateBack('/home/account-details/my-profile', { animated: false });

						}
						this.isLoading = false;
					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message'], 'top');

			})
		)
	}

	sellerRegistration() {
		let formData = new FormData();
		let customNotification = {};
		const encryptData = this.commonService.localStorageGet("seller_registration");
		if (encryptData) formData.append('data', encryptData);
		if (this.commonService.sellerIdProof) formData.append('id_proof', this.commonService.sellerIdProof);
		if (this.commonService.sellerCertificate) formData.append('cr_certificate', this.commonService.sellerCertificate);
		this.subscription.add(
			this.dataService.post(URLS.sellerRegistration, formData).subscribe(data => {
				if (data['code'] == 200) {
					this.commonService.localStorageRemove('seller_registration');
					this.commonService.sellerIdProof = null;
					this.commonService.sellerCertificate = null;
					let userData = data['data'];
					if (userData['session_token']) {
						this.commonService.setEvent('profileData', 'true');
						this.commonService.accessToken = userData['session_token'];
						this.commonService.isLoggedIn = true;
						this.commonService.localStorageSet('accessToken', userData['session_token']);
						this.verifyEmail = false;
						this.otpInput = null;
						this.codeInput.reset();
						let userResponseInfo = {
							user_type: userData['user_type'] ? userData['user_type'] : '',
							customer_type: userData['customer_type'] ? userData['customer_type'] : '',
							seller_type: userData['seller_type'] ? userData['seller_type'] : '',
							user_profile: userData['seller_type'] && userData['seller_type'] != 'NONE' ? userData['seller_type'] : userData['customer_type'],
							approval_status: userData['approval_status'] ? userData['approval_status'] : '',
							seller_approval_status: userData['seller_approval_status'] ? userData['seller_approval_status'] : '',
							isCustomer: userData['user_type'] == 'BOTH' || userData['user_type'] == this.profileEnum.Customer ? true : false,
							isSeller: userData['user_type'] == 'BOTH' || userData['user_type'] == this.profileEnum.Seller ? true : false,
							name: userData['first_name'] + ' ' + userData['last_name'],

						}
						this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
						this.commonService.setEvent('userResponseInfo', userResponseInfo);
						this.commonService.setEvent('sidemenuChnage', "true");
						customNotification['message'] = data['message'];
						customNotification['type'] = 'warning';
						// customNotification['link'] = '/home/account-details/manage-company-detail';
						customNotification['link'] = '/home/document-registration';
						this.commonService.localStorageSet('user_data', JSON.stringify(this.commonService.userData));
						setTimeout(() => {
							this.commonService.setEvent('alertMessage', customNotification);
						}, 500);
						this.commonService.callmenuData();

						// this.navCtrl.navigateRoot(['/home/account-details/my-profile'], { animated: false });
						this.navCtrl.navigateRoot(['/home/document-registration'], { animated: false });
					}

				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
					this.verifyEmail = false;
					this.otpInput = null;
					this.codeInput.reset();
					this.location.back();
				}
				this.isLoading = false;
			}, error => {
				this.toasterService.errorToast(error['message'], 'top');
				this.verifyEmail = false;
				this.otpInput = null;
				this.codeInput.reset();
				this.location.back();
			})
		)
	}

	customerRegistration() {
		let param = {
			data: this.commonService.localStorageGet("customer_registration")
		}
		this.subscription.add(
			this.dataService.post(URLS.customerRegistration, param).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					this.commonService.localStorageRemove('customer_registration');
					this.commonService.setEvent('profileData', 'true');
					let userData = data['data'];
					this.commonService.accessToken = userData['session_token'];
					this.commonService.isLoggedIn = true;
					this.commonService.localStorageSet('accessToken', userData['session_token']);
					this.verifyEmail = false;
					this.otpInput = null;
					this.codeInput.reset();
					let userResponseInfo = {
						user_type: userData['user_type'] ? userData['user_type'] : '',
						customer_type: userData['customer_type'] ? userData['customer_type'] : '',
						seller_type: userData['seller_type'] ? userData['seller_type'] : '',
						user_profile: userData['seller_type'] && userData['seller_type'] != 'NONE' ? userData['seller_type'] : userData['customer_type'],
						approval_status: userData['approval_status'] ? userData['approval_status'] : '',
						seller_approval_status: userData['seller_approval_status'] ? userData['seller_approval_status'] : '',
						isSeller: false,
						isCustomer: userData['user_type'] == 'BOTH' || userData['user_type'] == this.profileEnum.Customer ? true : false,
						name: userData['first_name'] + ' ' + userData['last_name'],
					}
					this.commonService.setEvent('userResponseInfo', userResponseInfo);
					let type = userData['seller_type'] || userData['customer_type'] == this.profileEnum.Individual ? "Individual" : "Enterprise";
					this.commonService.localStorageSet('info', JSON.stringify(userResponseInfo));
					let customNotification = {};
					this.commonService.callmenuData();
					this.commonService.localStorageSet('user_data', JSON.stringify(this.commonService.userData));
					if (userData['customer_type'] != 'INDIVIDUAL') {
						customNotification['message'] = data['message'];
						customNotification['type'] = 'warning';
						// this.navCtrl.navigateRoot(['/home/account-details']);
						this.navCtrl.navigateRoot(['/home/document-registration']);
						setTimeout(() => {
							this.commonService.setEvent('alertMessage', customNotification);
						}, 500);
					}
					else {
						if (this.locationData && this.locationData.productData) {
							let categoryName;
							let productName;
							if (this.locationData.productData.category && this.locationData.productData.name) {
								categoryName = this.commonService.trimUrl(this.locationData.productData.category);
								productName = this.commonService.trimUrl(this.locationData.productData.name);
							}
							else {
								categoryName = this.commonService.trimUrl(this.locationData.productData.category_name);
								productName = this.commonService.trimUrl(this.locationData.productData.product_name);
							}


							this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + this.locationData.productData.product_id,
								{
									animated: false, "state": {
										"breadcrumb": this.locationData.productData.type,
										"url": '/home/product-detail/' + this.locationData.productData.product_id,
										"productDetail": true,
										productData: this.locationData.productData
									}
								});
						} else {
							// this.navCtrl.navigateRoot(['/home']);
							this.navCtrl.navigateRoot(['/home/document-registration'], { animated: false });

						}
					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
					this.verifyEmail = false;
					this.otpInput = null;
					this.codeInput.reset();
					this.location.back();
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.verifyEmail = false;
				this.otpInput = null;
				this.codeInput.reset();
				this.location.back();
				this.toasterService.errorToast(error['message'], 'top');

			})
		)

	}
	onOtpChanged(event) {
		this.otpInput = event;
		if (event.length < 4) {
			this.disableBtn = true;
		} else {
			this.disableBtn = false;
		}

	}

	cancel() {
		this.otpInput = null;
		this.codeInput.reset();
		if (this.fromPage == 'my-profile') {
			this.navCtrl.navigateBack('/home/account-details/my-profile', { animated: false });
		} else {
			this.commonService.localStorageClear();
			this.location.back();
		}
	}


}
