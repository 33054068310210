import { CommonModule, DatePipe, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { CodeInputModule } from 'angular-code-input';
import { StarRatingModule } from 'ionic5-star-rating';
import { NgxPaginationModule } from 'ngx-pagination';
import { SwiperModule } from 'swiper/angular';
import { CommonPipe } from '../_pipe/common.pipe';
import { indianCurrencyCommaSeprator } from '../_pipe/indian-currency-comma-seprator.pipe';
import { PriceFormatPipe } from '../_pipe/price.pipe';
import { AboutUsComponent } from '../components/about-us/about-us.component';
import { AddBlogComponent } from '../components/add-blog/add-blog.component';
import { AddEditProductFilterComponent } from '../components/add-edit-product-filter/add-edit-product-filter.component';
import { AddEditVehicleComponent } from '../components/add-edit-vehicle/add-edit-vehicle.component';
import { AddQuoteComponent } from '../components/add-quote/add-quote.component';
import { BlogDetailComponent } from '../components/blog-detail/blog-detail.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { ConsultantRequestComponent } from '../components/consultant-request/consultant-request.component';
import { ContactUsComponent } from '../components/contact-us/contact-us.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { DeliveryAddressComponent } from '../components/delivery-address/delivery-address.component';
import { EscrowComponent } from '../components/escrow/escrow.component';
import { FaqComponent } from '../components/faq/faq.component';
import { ManageVehiclesComponent } from '../components/manage-vehicles/manage-vehicles.component';
import { MyDocumentComponent } from '../components/my-document/my-document.component';
import { MyEarningsComponent } from '../components/my-earnings/my-earnings.component';
import { MyQuoteRequestsComponent } from '../components/my-quote-requests/my-quote-requests.component';
import { NewsletterDetailComponent } from '../components/newsletter-detail/newsletter-detail.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { OrderSummaryComponent } from '../components/order-summary/order-summary.component';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { ProductSearchComponent } from '../components/product-search/product-search.component';
import { QuotesDetailComponent } from '../components/quotes-detail/quotes-detail.component';
import { RejectProductComponent } from '../components/reject-product/reject-product.component';
import { RequestQuoteComponent } from '../components/request-quote/request-quote.component';
import { TermsOfUseComponent } from '../components/terms-of-use/terms-of-use.component';
import { ThankYouComponent } from '../components/thank-you/thank-you.component';
import { LoaderComponent } from '../loader/loader.component';
import { ActionConfirmModalComponent } from '../modal/action-confirm-modal/action-confirm-modal.component';
import { EWayModalComponent } from '../modal/e-way-modal/e-way-modal.component';
import { OrderTrackingModalComponent } from '../modal/order-tracking-modal/order-tracking-modal.component';
import { OrderWarehouseModalComponent } from '../modal/order-warehouse-modal/order-warehouse-modal.component';
import { ProductAddSuccessModalComponent } from '../modal/product-add-success-modal/product-add-success-modal.component';
import { ProductSaveDraftModalComponent } from '../modal/product-save-draft-modal/product-save-draft-modal.component';
import { NotFoundComponent } from '../not-found/not-found.component';
import { PaymentGatewayWaitingModalComponent } from '../payment-gateway-waiting-modal/payment-gateway-waiting-modal.component';
import { AccountMenuComponent } from './../components/account-menu/account-menu.component';
import { AddAddressComponent } from './../components/add-address/add-address.component';
import { AddEditCompanyAddressComponent } from './../components/add-edit-company-address/add-edit-company-address.component';
import { AddUserComponent } from './../components/add-user/add-user.component';
import { AddressComponent } from './../components/address/address.component';
import { BankDetailsComponent } from './../components/bank-details/bank-details.component';
import { BannerComponent } from './../components/banner/banner.component';
import { CategoriesComponent } from './../components/categories/categories.component';
import { ChangePasswordComponent } from './../components/change-password/change-password.component';
import { CompanyInfoComponent } from './../components/company-info/company-info.component';
import { CreateNewPasswordComponent } from './../components/create-new-password/create-new-password.component';
import { DocumentComponent } from './../components/document/document.component';
import { EnterpriseDetailComponent } from './../components/enterprise-detail/enterprise-detail.component';
import { FooterComponent } from './../components/footer/footer.component';
import { ForgotPasswordComponent } from './../components/forgot-password/forgot-password.component';
import { HeaderMenuComponent } from './../components/header-menu/header-menu.component';
import { HeaderComponent } from './../components/header/header.component';
import { LoginComponent } from './../components/login/login.component';
import { ManageCompanyDetailsComponent } from './../components/manage-company-details/manage-company-details.component';
import { ManageUserComponent } from './../components/manage-user/manage-user.component';
import { MyProfileComponent } from './../components/my-profile/my-profile.component';
import { ProductCardComponent } from './../components/product-card/product-card.component';
import { ProductSliderComponent } from './../components/product-slider/product-slider.component';
import { RegistrationTypeComponent } from './../components/registration-type/registration-type.component';
import { RegistrationComponent } from './../components/registration/registration.component';
import { SellerAddressComponent } from './../components/seller-address/seller-address.component';
import { SellerRegistrationComponent } from './../components/seller-registration/seller-registration.component';
import { SubCategoriesComponent } from './../components/sub-categories/sub-categories.component';
import { TableListingComponent } from './../components/table-listing/table-listing.component';
import { TwoFactorAuthComponent } from './../components/two-factor-auth/two-factor-auth.component';
import { UpperHeaderComponent } from './../components/upper-header/upper-header.component';
import { PersonalDetailComponent } from './../personal-detail/personal-detail.component';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { CapitalizeFirstDirective } from './capitalize-first.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';

const COMPONENTS = [
	ClickOutsideDirective,
	LoginComponent,
	RegistrationComponent,
	ForgotPasswordComponent,
	CreateNewPasswordComponent,
	TwoFactorAuthComponent,
	FooterComponent,
	HeaderComponent,
	BannerComponent,
	ProductSliderComponent,
	ProductCardComponent,
	HeaderMenuComponent,
	AccountMenuComponent,
	MyProfileComponent,
	MyDocumentComponent,
	AddressComponent,
	AddAddressComponent,
	ChangePasswordComponent,
	NotificationComponent,
	UpperHeaderComponent,
	SellerRegistrationComponent,
	ManageCompanyDetailsComponent,
	BankDetailsComponent,
	CategoriesComponent,
	DocumentComponent,
	CompanyInfoComponent,
	SellerAddressComponent,
	PersonalDetailComponent,
	EnterpriseDetailComponent,
	ManageUserComponent,
	ManageVehiclesComponent,
	TableListingComponent,
	AddUserComponent,
	AddEditCompanyAddressComponent,
	AddEditVehicleComponent,
	SubCategoriesComponent,
	RegistrationTypeComponent,
	CapitalizeFirstDirective,
	RequestQuoteComponent,
	OrderSummaryComponent,
	DeliveryAddressComponent,
	AddQuoteComponent,
	QuotesDetailComponent,
	MyQuoteRequestsComponent,
	MyEarningsComponent,
	BlogDetailComponent,
	NewsletterDetailComponent,
	ConsultantRequestComponent,
	ContactUsComponent,
	FaqComponent,
	PrivacyPolicyComponent,
	AboutUsComponent,
	AddBlogComponent,
	RejectProductComponent,
	NotFoundComponent,
	TermsOfUseComponent,
	ProductSearchComponent,
	BlockCopyPasteDirective,
	DashboardComponent,
	TwoDigitDecimalNumberDirective,
	AddEditProductFilterComponent
];

const ModalComponent = [
	ProductSaveDraftModalComponent,
	ProductAddSuccessModalComponent,
	EscrowComponent,
	ThankYouComponent,
	ActionConfirmModalComponent,
	ConfirmationModalComponent,
	OrderTrackingModalComponent,
	OrderWarehouseModalComponent,
	EWayModalComponent,
	PaymentGatewayWaitingModalComponent,
	ConfirmationModalComponent,
	OrderTrackingModalComponent,
	OrderWarehouseModalComponent,
	LoaderComponent

]


const MODULES = [
	CommonModule,
	IonicModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	CodeInputModule,
	SwiperModule,
	NgSelectModule,
	NgxPaginationModule,
	StarRatingModule,
	NgOptimizedImage
];

const PIPES = [
	CommonPipe,
	PriceFormatPipe,
	indianCurrencyCommaSeprator
];
@NgModule({
	imports: [
		...MODULES,
	],
	declarations: [...COMPONENTS, ...ModalComponent, ...PIPES],
	entryComponents: [...COMPONENTS, ...ModalComponent, ...PIPES],
	exports: [
		...MODULES,
		...COMPONENTS,
		...ModalComponent,
		...PIPES
	],
	providers: [
		DatePipe,
		NativeGeocoder,
		DecimalPipe,
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class SharedModule { }
