import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/_common/customvalidators';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
export interface UpdateBank {
	company_bank_details_id?: number;
	bank_account_name?: string;
	bank_account_number?: string;
	ifsc_code?: string;
	is_default_bank_details?: boolean;
	only_is_default_changed?: boolean;
}

@Component({
	selector: 'app-bank-details',
	templateUrl: './bank-details.component.html',
	styleUrls: ['./bank-details.component.scss'],
})
export class BankDetailsComponent implements OnInit {
	isBankLoader = false;
	public bankForm: FormGroup;
	@Input() progress;
	public banksForm: FormGroup;
	public subscription = new Subscription();
	isLoading = false;
	isBankLoading = false;
	bankParam: any = [];
	@Input() isSellerApproval = false;
	companyInfo: any = [];
	modal: any;
	isDeleteShow: boolean = true;
	isSubmitVisible: boolean = false;
	newBankCount: number = 0

	public validation_messages =
		{
			account_no: [
				{ type: 'required', message: 'Please enter account number' },
				{ type: 'minlength', message: 'Minimum account number should be 6 character long' },
				{ type: 'maxlength', message: 'Maximum account number should be 18 character long' },
				{ type: 'invalidString', message: 'Please enter valid account number' },
				{ type: 'invalidName', message: 'Please enter valid account number' },
				{ type: 'invalidBank', message: 'Please enter valid account number' },
			],
			account_holder: [
				{ type: 'required', message: 'Please enter account holder name' },
				{ type: 'invalidString', message: 'Please enter valid account holder' },
				{ type: 'invalidName', message: 'Please enter valid account holder' },
				{ type: 'maxlength', message: 'Maximum account holder name should be 100 character long' },
			],
			ifsc: [
				{ type: 'required', message: 'Please enter IFSC code' },
				{ type: 'maxlength', message: 'Maximum ifsc should be 11 character long' },
				{ type: 'invalidifsc', message: 'Please Enter valid IFSC code' },

			],
		}

	constructor(
		private formBuilder: FormBuilder,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private alertCtrl: AlertController,
		private commonService: CommonService,
		private modalService: ModalService,
		public getPlatformService: GetPlatformService
	) { }

	ngOnInit() {

		this.initializeForm();
		this.getCompanyDetail();

	}

	initializeForm() {
		this.bankForm = this.formBuilder.group({
			banks: new FormArray([this.addBank()], Validators.required)
		})
		this.banksForm = this.addBank();
	}

	getCompanyDetail() {
		this.bankForm.reset();
		this.bank.reset();
		this.initializeForm();
		this.subscription.add(
			this.dataService.get(URLS.getCompanyBankDetail).subscribe(data => {
				if (data['code'] == 200) {
					this.companyInfo = []
					this.companyInfo = data['data'];
					if (this.companyInfo.length) {
						for (let i = 0; i < this.companyInfo.length; i++) {
							if (i > 0) {
								this.bank.push(this.addBank());
							}
						}
						for (const j in this.bank.controls) {
							for (const k in this.banksForm.controls) {
								if (k == 'company_bank_details_id') {
									this.bank.controls[j]['controls'][k].setValue(this.companyInfo[j]['company_bank_details_id']);
								}
								if (k == 'account_no') {
									this.bank.controls[j]['controls'][k].setValue(this.companyInfo[j]['bank_account_number']);
									if (this.companyInfo[j]['approval_status'] == 'PENDING') this.bank.controls[j]['controls'][k].disable(false);
								}
								if (k == 'account_holder') {
									this.bank.controls[j]['controls'][k].setValue(this.companyInfo[j]['bank_account_name']);
									if (this.companyInfo[j]['approval_status'] == 'PENDING') this.bank.controls[j]['controls'][k].disable(true);
								}
								if (k == 'ifsc') {
									this.bank.controls[j]['controls'][k].setValue(this.companyInfo[j]['ifsc_code']);
									if (this.companyInfo[j]['approval_status'] == 'PENDING') this.bank.controls[j]['controls'][k].disable(true);
								}
								if (k == 'isEdit') {
									this.bank.controls[j]['controls'][k].setValue(true);
								}
								if (k == 'approval_status') {
									this.bank.controls[j]['controls'][k].setValue(this.companyInfo[j]['approval_status'] == 'PENDING' ? true : false);
								}
								if (k == 'isDefault') {
									this.bank.controls[j]['controls'][k].setValue(this.companyInfo[j]['is_default_bank_details'] == 1 ? true : false);
									if (this.companyInfo[j]['approval_status'] == 'PENDING') this.bank.controls[j]['controls'][k].disable(true);
								}

							}
						}
					}

					let tempBankArray = [];
					this.bank['value'].forEach(element => {
						if (!element.company_bank_details_id) {
							tempBankArray.push(element)
						}
					});
					if (tempBankArray && tempBankArray.length > 0) {
						this.isSubmitVisible = true;
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	addBank(data?): FormGroup {
		return new FormGroup({
			'company_bank_details_id': new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([]),
			}),
			'account_no': new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(18), CustomValidator.noWhiteSpaceValidator, CustomValidator.bankNumberValidator]),
			}),
			'account_holder': new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator, Validators.maxLength(100), CustomValidator.alphabetValidator]),
			}),
			'ifsc': new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(11), CustomValidator.noWhiteSpaceValidator, CustomValidator.ifscCodeValidator]),
			}),
			'isEdit': new FormControl({ value: false, disabled: false }, {
				validators: Validators.compose([]),
			}),
			'isDefault': new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([]),
			}),
			'approval_status': new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([]),
			}),

		})
	}

	get bank(): FormArray {
		return this.bankForm.controls["banks"] as FormArray;
	}

	addMoreBank() {
		// Check if all previous bank details are filled
		this.newBankCount++
		if (this.isAnyBankDetailEmpty()) {
			this.toasterService.errorToast("Please fill in all previous bank details before adding a new one", 'top');
			return;
		}

		if (this.bank.length > 10) {
			this.toasterService.errorToast("You cannot add more than 10 addresses", 'top');
			return;
		}

		this.bank.push(this.addBank());
		let tempBankArray = [];
		this.bank['value'].forEach(element => {
			if (!element.company_bank_details_id) {
				tempBankArray.push(element)
			}
		});
		if (tempBankArray && tempBankArray.length > 0) {
			this.isSubmitVisible = true;
		}

	}

	isAnyBankDetailEmpty(): boolean {
		// Iterate through existing bank details and check if any of them is empty

		for (let i = 0; i < this.bank.length; i++) {
			const bankGroup = this.bank.at(i) as FormGroup;
			if (
				bankGroup.get('account_holder').value === null ||
				bankGroup.get('account_no').value === null ||
				bankGroup.get('ifsc').value === null
			) {
				return true; // Return true if any detail is empty
			}
		}
		return false; // Return false if all details are filled
	}



	setDefaultBank(event: CustomEvent, ind?: number, data?: any) {
		const clickedBank = this.bank.at(ind).get('isDefault');

		if (event.detail.checked) {
			this.bank.controls.forEach((control, index) => {
				if (index !== ind) {
					control.get('isDefault').setValue(false, { emitEvent: false });
				}
			});
		}

		clickedBank.setValue(event.detail.checked, { emitEvent: false });
	}

	labelClick(event: Event, ind?: number, data?: any) {
		if (this.isBankLoading || data.value.approval_status) {
			return
		}
		event.preventDefault();
		const clickedBank = this.bank.at(ind).get('isDefault');
		clickedBank.setValue(!clickedBank.value, { emitEvent: false });
	}


	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
	}

	isnumeric(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	get filteredArray() {
		return this.bank.value.filter(item => item.isDefault || item.is_default_bank_details);
	}

	onBankSubmit() {
		this.bankParam = [];
		let paramsLocation = {};
		let myLoation;
		for (const i in this.bankForm.controls) {
			if (i == "banks") {
				for (const j in this.bank.controls) {
					for (const k in this.banksForm.controls) {
						if (k == 'account_no' && this.bank.controls[j]['controls'][k].value) {
							paramsLocation['bank_account_number'] = this.bank.controls[j]['controls'][k].value;
						}
						if (k == 'account_holder' && this.bank.controls[j]['controls'][k].value) {
							paramsLocation['bank_account_name'] = this.bank.controls[j]['controls'][k].value?.trim();
						}
						if (k == 'ifsc' && this.bank.controls[j]['controls'][k].value) {
							paramsLocation['ifsc_code'] = this.bank.controls[j]['controls'][k].value?.trim();
						}
						if (k == 'isDefault' && this.bank.controls[j]['controls'][k].value) {
							paramsLocation['is_default_bank_details'] = this.bank.controls[j]['controls'][k].value;
						}
						if (k == 'isDefault' && !this.bank.controls[j]['controls'][k].value) {
							paramsLocation['is_default_bank_details'] = false;
						}
						if (k == 'isEdit' && !this.bank.controls[j]['controls'][k].value) {
							paramsLocation['isEdit'] = false;
						}
						if (k == 'isEdit' && this.bank.controls[j]['controls'][k].value) {
							paramsLocation['isEdit'] = true;
						}
						this.bank.controls[j]['controls'][k].markAsDirty();
						this.bank.controls[j]['controls'][k].markAsTouched();
						this.bank.controls[j]['controls'][k].updateValueAndValidity();
					}
					myLoation = { ...paramsLocation }
					if (!myLoation.isEdit) this.bankParam.push(myLoation);
					this.bankParam.forEach(element => {
						delete element['isEdit'];
					});

				}
			} else {
				this.bankForm.controls[i].markAsDirty();
				this.bankForm.controls[i].updateValueAndValidity();
				this.bankForm.controls[i].markAsTouched();

			}
		}
		if (this.bankForm.invalid) {
			return;
		}
		let isNewBank = this.bank.value.filter(company => !company.company_bank_details_id);
		if (isNewBank && this.filteredArray.length < 1) {
			this.toasterService.warningToast("Please Select Default Bank");
			return;
		}


		const concatenatedArray = this.bank.controls.reduce((acc, control) => [...acc, control.value], []);

		// Check for duplicates based on bank account details
		let isDuplicate = concatenatedArray.some((item, index, array) => array.findIndex(t => t.account_no === item.account_no) !== index);

		// Display warning if duplicates found
		if (isDuplicate) {
			this.toasterService.warningToast("Entered duplicate bank account. Please enter unique bank account.");
			return;
		}
		let param = {
			company_bank_details: this.bankParam
		}
		if (this.bankParam.length > 0) {
			this.isLoading = true;
			this.subscription.add(
				this.dataService.post(URLS.addCompanyBankDetail, param).subscribe(data => {
					if (data['code'] == 200) {
						this.isLoading = false;
						this.isSubmitVisible = false

						this.getCompanyDetail();
						if (this.progress != 100) {
							let obj = {
								component: SuccessModalComponent,
								backdropDismiss: true,
								canDismiss: true,
								cssClass: 'success-modal',
								componentProps: {
									title: "Thank You For Submission",
									description: "Your Seller Account Request is under processing, We will update you very soon."
								}
							};
							this.modal = this.modalService.openModal(obj);
							this.modal.then(value => {
							}, error => {
								console.log(error);
							});
							if (this.companyInfo.length < 1) this.getProgressBar();
						}
						else {
							this.toasterService.successToast(data['message']);
						}
					}
					else {
						this.isSubmitVisible = true
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);

					}
				}, error => {
					this.isLoading = false;
					this.isSubmitVisible = true
					this.toasterService.errorToast(error['message']);
					if (error['data'].length > 0 && this.bank.value.length > 0) {
						this.bank.value.forEach(element => {
							error['data'].forEach(element2 => {
								if (element2['bank_account_number'] == element['account_no'] && element2['error']) {
									element['error'] = element2['error']
								}
							});
						});
					}

				})
			)
		}
		else {
			if (this.bank.length > 10) {
				this.toasterService.warningToast("You cannot add more than 10 address", 'top');
				return;
			}
			else {
				this.isLoading = true;
				this.toasterService.warningToast("Please add bank first");
				setTimeout(() => {
					this.isLoading = false;
				}, 3200)
			}
		}

	}

	update(val) {

		for (const i in val.controls) {
			val.controls[i].markAsDirty();
			val.controls[i].updateValueAndValidity();
			val.controls[i].markAsTouched();
		}
		if (val.invalid) {
			return;
		}
		let matchCompany = this.companyInfo.filter(company => company.company_bank_details_id == val.value.company_bank_details_id)
		let param: UpdateBank = {};
		let bankChange = {};
		param.bank_account_name = val.value.account_holder.trim();
		param.bank_account_number = val.value.account_no.trim();
		param.company_bank_details_id = val.value.company_bank_details_id;
		param.ifsc_code = val.value.ifsc.trim();
		param.is_default_bank_details = val.value.isDefault;
		if (matchCompany[0].bank_account_name == val.value.account_holder && matchCompany[0].bank_account_number == val.value.account_no && matchCompany[0].ifsc_code == val.value.ifsc && matchCompany[0].is_default_bank_details != val.value.isDefault) {
			param.only_is_default_changed = true;
		}
		if (matchCompany[0].is_default_bank_details == val.value.isDefault) {
			if (matchCompany[0].bank_account_name != val.value.account_holder) {
				bankChange['bank_account_name'] = val.value.account_holder.trim();
			}
			if (matchCompany[0].bank_account_number != val.value.account_no) {
				bankChange['account_no'] = val.value.account_no.trim();
			}
			if (matchCompany[0].ifsc_code != val.value.ifsc) {
				bankChange['ifsc'] = val.value.ifsc.trim();
			}
			if (matchCompany[0].ifsc_code != val.value.ifsc) {
				bankChange['ifsc'] = val.value.ifsc.trim();
			}
			let bankLength = Object.keys(bankChange).length;
			if (bankLength < 1) {
				return;
			}
		}
		this.isBankLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.updateCompanyBankDetail, param).subscribe(data => {
				if (data['code'] == 200) {
					this.isBankLoading = false;
					val.value.approval_status = data.approval_status;
					val.value.is_default_bank_details = data.is_default_bank_details;
					this.getCompanyDetail();
					this.toasterService.successToast(data['message']);
				}
				else {
					this.isBankLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isBankLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}
	async removeBank(i, data?) {
		if (data.value.isDefault) {
			this.isDeleteShow = false;
			this.toasterService.warningToast("You need to change the default bank first to delete this bank details");
			setTimeout(() => {
				this.isDeleteShow = true;
			}, 3200)
			return;
		}

		this.newBankCount--;

		if (this.companyInfo.length == 1) {
			let matchCompany = this.companyInfo.filter(company => company.company_bank_details_id == data.value.company_bank_details_id)
			if (!data.value.isDefault && matchCompany.length == 1 && data.valid) {
				this.isDeleteShow = false;
				this.toasterService.warningToast('You have atleast one deafult bank detail')
				setTimeout(() => {
					this.isDeleteShow = true;
				}, 3200)
				return;
			}

		}
		if (data && data.value.account_no) {
			const alert = await this.alertCtrl.create({
				header: 'Are you sure ? ',
				message: "You want to delete this bank detail ?",
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						handler: () => {
						},
					},
					{
						text: 'Yes',
						role: 'confirm',
						handler: () => {
							this.deleteBank(i, data);
							if (this.newBankCount <= 0) {
								this.isSubmitVisible = false
							}
						},
					},
				],
			});
			await alert.present();
			const { role } = await alert.onDidDismiss();


		} else {
			this.bank.removeAt(i);
			this.isSubmitVisible = false;

			let tempBankArray = [];
			this.bank['value'].forEach(element => {
				if (!element.company_bank_details_id) {
					tempBankArray.push(element)
				}
			});
			if (tempBankArray && tempBankArray.length > 0) {
				this.isSubmitVisible = true;
			}
		}
	}

	deleteBank(i, data) {
		let param = {
			company_bank_details_id: data.value.company_bank_details_id
		}
		if (data.value.company_bank_details_id) {
			this.subscription.add(
				this.dataService.delete(URLS.deleteCompanyBankDetail, param).subscribe(data => {
					if (data['code'] == 200) {
						this.bank.removeAt(i);
						this.companyInfo.splice(i, 1);
						this.toasterService.successToast(data['message']);
					}
					else {
						this.isBankLoading = false;
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isBankLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}
		else {
			this.bank.removeAt(i);
		}
	}

	getProgressBar() {
		this.subscription.add(
			this.dataService.get(URLS.sellerProgressBar).subscribe(data => {
				if (data['code'] == 200) {
					this.progress = data['data']['progress'];
					this.isSellerApproval = true;
					let localSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
					let is_sub_user = false;
					let isSub_user_Admin = false;
					is_sub_user = this.commonService.localStorageGet('is_sub_user') == 'true' ? true : false;
					isSub_user_Admin = this.commonService.localStorageGet('is_sub_user_admin') == 'true' ? true : false;
					if (localSellerApproval && this.progress == 100 && !is_sub_user) {
						this.isSellerApproval = true;
						this.commonService.setEvent('isProgessCall', true)
					}
					else if (is_sub_user && !isSub_user_Admin) {
						this.isSellerApproval = true;
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	cancel() {
		this.isSubmitVisible = false
		this.getCompanyDetail();
	}


}
