import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { CommonService } from '../../services/common.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-my-earnings',
	templateUrl: './my-earnings.component.html',
	styleUrls: ['./my-earnings.component.scss'],
})
export class MyEarningsComponent implements OnInit {
	showFilter: boolean = false
	public subscription = new Subscription();
	public currentPage = 1;
	public pageNo = 1;
	public itemsPerPage = this.commonService.list;
	public pagesize = this.commonService.list;
	public total_earning = 0;
	public receivable_amount = 0;
	paginationVal = 10;
	searchObj = {};
	fromDate: any;
	toDate: any;
	searchText: any;
	public totalCount: any;
	maxDate: any;
	todaysDate = new Date();
	public isLoading = false;
	isEarning = false;
	widthConfig = ['100px', '150px', '150px', '150px', '150px', '150px'];
	lastSearchCriteria: any = {};

	column_settings = [
		{ primaryKey: 'order_id', header: 'Order id' },
		{ primaryKey: 'customer_name', header: 'Customer name' },
		{ primaryKey: 'qc_confirmed', header: 'Quality check confirmed on' },
		{ primaryKey: 'earning_amount', header: 'Earning amount' },
		{ primaryKey: 'payment_status', header: 'Payment status', payment_status: true },
		{ primaryKey: 'received_on', header: 'Received on' },
	];

	listOfAllData = []

	constructor(
		private dataService: ApiService,
		private toasterService: ToasterService,
		public commonService: CommonService,
		private datePipe: DatePipe,
		private decimalPipe: DecimalPipe,
		public getPlatformService: GetPlatformService,
		private modalController: ModalController

	) { }

	ngOnInit() {
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.getListData();
		this.commonService.scrollToTop();
	}

	onDateChange(datetime) {
		// if (this.fromDate > this.toDate) {
		// 	this.fromDate = this.toDate;
		// }
		// datetime.cancel(true);
	}
	filterShowButton() {
		this.showFilter = !this.showFilter;
	}

	closeFilterModal() {
		this.modalController.dismiss();
	}


	search() {
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return;
		}
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
			this.getListData();
		}
	}

	onSearch() {
		const currentSearchCriteria = {
			searchText: this.searchText,
			fromDate: this.fromDate,
			toDate: this.toDate
		};

		if (
			currentSearchCriteria.searchText === this.lastSearchCriteria.searchText &&
			currentSearchCriteria.fromDate === this.lastSearchCriteria.fromDate &&
			currentSearchCriteria.toDate === this.lastSearchCriteria.toDate
		) {
			return;
		}

		this.searchObj = {};
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
		}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toasterService.errorToast("Please select From Date");
			}
			if (!this.toDate) {
				this.toasterService.errorToast("Please select To Date");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		this.currentPage = 1;
		this.pageNo = 1;
		if (Object.keys(this.searchObj).length > 0) {
			// Update the last search criteria
			this.lastSearchCriteria = currentSearchCriteria;
			this.getListData();
		}
	}

	getListData(event?) {
		let param = {}
		param['page'] = this.pageNo;
		param['limit'] = this.pagesize;
		if (this.searchObj) {
			param = {
				...param,
				...this.searchObj
			}
		}
		if (event) {
			if (event.sort_by.primaryKey == "qc_confirmed") {
				param['sort_by'] = "qc_aprroved_date"
			}
			else if (event.sort_by.primaryKey == "earning_amount") {
				param['sort_by'] = "total_price"
			}
			else {
				param['sort_by'] = event.sort_by.primaryKey
			}
			param['sort_type'] = event.sort_type;
		}
		this.isEarning = true;
		this.subscription.add(
			this.dataService.get(URLS.manageEarnings, param).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.listOfAllData = [];
					let earningsList = data['data']['seller_earning_list'];
					this.totalCount = data['data']['total_count'];
					this.total_earning = data['data']['total_earning'] ? data['data']['total_earning'] : 0;
					this.receivable_amount = data['data']['receivable_amount'] ? data['data']['receivable_amount'] : 0;
					if (earningsList && earningsList.length) {
						earningsList.forEach(element => {
							let obj = {
								order_id: element.order_id ? element.order_id : "-",
								customer_name: element.customer_name ? element.customer_name : '-',
								qc_confirmed: this.datePipe.transform(element["qc_aprroved_date"], 'dd-MM-yyyy h:mm a'),
								earning_amount: element.total_price ? this.decimalPipe.transform(element.total_price) : '-',
								payment_status: element.payment_status ? element.payment_status.toLowerCase() : '-',
								received_on: element.received_on ? this.datePipe.transform(element.received_on, 'dd-MM-yyyy hh:mm a') : '-'
							}
							if (element.payment_status == "CONFIRMED") {
								obj['payment_status_type'] = 'success'
							}
							if (element.payment_status == "PROCESSING") {
								obj['payment_status_type'] = 'wait'
							}
							if (element.payment_status == "PENDING") {
								obj['payment_status_type'] = 'warning'
							}
							this.listOfAllData.push(obj);
						});

					}
					setTimeout(() => {
						this.isEarning = false;
					}, 4000)
				}
				else {
					this.isEarning = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isEarning = false;
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	payoutRequest() {
		const params = {
			receivable_amount: this.receivable_amount.toString()
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.payoutRequest, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.toasterService.successToast(data['message']);
					this.isLoading = false;
				}
				else {
					this.toasterService.errorToast(data['message']);
					this.isLoading = false;
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
				this.isLoading = false;

			})
		)
	}

	onChange(event): void {
		this.currentPage = event;
		this.pageNo = this.currentPage;
		this.getListData()
	}

	changeListItem() {
		this.currentPage = 1;
		this.pageNo = 1;
		this.pagesize = this.paginationVal;
		this.itemsPerPage = this.paginationVal
		this.getListData();
	}

	closeFilter() {
		if (Object.keys(this.searchObj).length > 0) {
			this.searchObj = {};
			this.searchText = null;
			this.fromDate = null;
			this.toDate = null;
			this.pageNo = 1;
			this.pagesize = 10;
			this.currentPage = 1;
			this.getListData();
			this.modalController.dismiss()
		} else {
			this.searchText = null;
			this.fromDate = null;
			this.toDate = null;
			this.pageNo = 1;
			this.pagesize = 10;
			this.currentPage = 1;
		}
	}

}
