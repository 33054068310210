import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../_common/customvalidators';
import { CommonService } from '../../services/common.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-blog-detail',
	templateUrl: './blog-detail.component.html',
	styleUrls: ['./blog-detail.component.scss'],
})
export class BlogDetailComponent implements OnInit {

	public subscription = new Subscription();
	public blogData = [];
	public recentPost = [];
	public page = 1;
	public limit = 1;
	public commentPage = 1;
	public commentLimit = 3;
	public enableNext: boolean = true;
	public enableNextRecent: boolean = true;
	public total_count: any
	public isLoading: boolean = false;
	public blog_id: any;
	public blogCommentList = [];
	public enableNextComment: boolean = false;
	public commentCount: any;
	blogFormGroup: FormGroup;
	isDisabledPrevArrowButton: boolean = false;
	isDisabledNextArrowButton: boolean = false;

	public validation_messages = {
		email: [
			{ type: 'required', message: 'Please enter email' },
			{ type: 'invalidEmail', message: 'Enter valid email id' },
		],
		comment: [
			{ type: 'required', message: 'Please enter comment' },
		],

	}

	constructor(
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		public commonService: CommonService
	) { }

	ngOnInit() {
		this.blogFormGroup = this.initializeBlogFromGroup();
		if (!this.commonService.isLoggedIn) {
			this.f1.email.setValidators([Validators.required, CustomValidator.emailValidator]);
			this.f1.email.updateValueAndValidity();
		}
		this.route.params.subscribe(params => {
			const id = params['id'];
			this.blog_id = +id
			if (this.blog_id) {
				this.getListData();
				this.getRecentlist();
				this.getCommentList();
			}
		});
	}

	get f1() { return this.blogFormGroup.controls; }

	getListData() {
		const params = {
			blog_id: this.blog_id
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getBlog, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					let blogList = data['data']['blogList'];
					if (!blogList || !blogList.length) {
						this.isLoading = false;
						this.toasterService.errorToast("Blog not found");
						this.navCtrl.navigateRoot('/blog', { animated: false });
						return;
					}
					this.enableNext = data['data']['enable_next'];
					this.total_count = data['data']['total_count'];
					this.blogData = [];
					blogList.forEach(element => {
						let obj = {
							image_url: element.image_url,
							name: element.name,
							date: element.created_at,
							description: element.description,
							blog_id: element.blog_id,
							email: element.email,
							user_name: element.user_name,
							category_name: element.category_name,
							category_description: element.category_description,
							total_records: element.total_records
						}
						this.blogData.push(obj);
					});
					setTimeout(() => {
						this.isLoading = false;
					}, 2000);
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	getRecentlist() {
		const params = {
			is_recent_request: true
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getBlog, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					let recentData = data['data']['blogList']
					this.enableNextRecent = data['data']['enable_next'];
					this.recentPost = [];
					recentData.forEach(element => {
						let obj = {
							image_url: element.image_url,
							name: element.name,
							date: element.created_at,
							description: element.description,
							blog_id: element.blog_id,
							email: element.email,
							user_name: element.user_name,
							category_name: element.category_name,
							category_description: element.category_description,
							total_records: element.total_records
						}
						this.recentPost.push(obj);
					});
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	getCommentList(isCommentNext?) {

		let params = {
			blog_id: this.blog_id,
			page: this.commentPage,
			limit: this.commentLimit,
		}

		if (this.commonService.isLoggedIn) {
			const email = this.commonService.localStorageGet('user_email') ? this.commonService.localStorageGet('user_email') : null
			params['email'] = email
		}

		this.subscription.add(
			this.dataService.get(URLS.getBlogComments, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					let commentList = data['data']['blogCommentsList']
					this.enableNextComment = data['data']['enable_next'];
					this.commentCount = data['data']['total_count'];
					if (!isCommentNext) {
						this.blogCommentList = [];
					}
					commentList.forEach(element => {
						let obj = {
							user_name: element.user_name,
							comment: element.comment,
							created_at: element.created_at,
							is_liked: element.is_liked,
							like_count: element.like_count,
							blog_comment_id: element.blog_comment_id,
							like_id: element.like_id,
							email: element.email
						}
						this.blogCommentList.push(obj);
					});
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	loadMoreComments() {
		if (this.enableNextComment) {
			this.commentPage++;
			this.getCommentList(true);
		}
	}

	prevBlog() {
		if (this.recentPost && this.recentPost.length) {
			const presentBlog = this.recentPost.find(element => element.blog_id == this.blog_id);
			const presentBlogIndex = this.recentPost.indexOf(presentBlog);
			if (presentBlogIndex > 0) {
				const data = this.recentPost[presentBlogIndex - 1];
				this.gotToBlogDetail(data.blog_id);
			}
		}
	}

	nextBlog() {
		if (this.recentPost && this.recentPost.length) {
			const presentBlog = this.recentPost.find(element => element.blog_id == this.blog_id);
			const presentBlogIndex = this.recentPost.indexOf(presentBlog);
			if (presentBlogIndex < this.recentPost.length - 1) {
				const data = this.recentPost[presentBlogIndex + 1];
				this.gotToBlogDetail(data.blog_id);
			}
		}
	}

	isPrevDisabled() {
		if (this.recentPost && this.recentPost.length) {
			const presentBlog = this.recentPost.find(element => element.blog_id == this.blog_id);
			const presentBlogIndex = this.recentPost.indexOf(presentBlog);
			return presentBlogIndex <= 0;
		}
		return true;
	}

	isNextDisabled() {
		if (this.recentPost && this.recentPost.length) {
			const presentBlog = this.recentPost.find(element => element.blog_id == this.blog_id);
			const presentBlogIndex = this.recentPost.indexOf(presentBlog);
			return presentBlogIndex >= this.recentPost.length - 1;
		}
		return true;
	}

	gotToBlogDetail(blog_id) {
		if (blog_id) {
			this.navCtrl.navigateRoot('blog/' + blog_id, { animated: false });
		}
	}

	goToAddBlog() {
		this.navCtrl.navigateRoot('/add-blog', { animated: false });
	}

	addBlogComment() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}
		if (this.blogFormGroup.invalid) {
			return;
		}

		let params = {
			blog_id: this.blog_id,
			comment: this.f1.comment.value,
		}

		if (!this.commonService.isLoggedIn) {
			params['email'] = this.f1.email.value;
		} else {
			const data = JSON.parse(this.commonService.localStorageGet('info'));
			const email = this.commonService.localStorageGet('user_email') ? this.commonService.localStorageGet('user_email') : null
			if (email && data && data.name) {
				params['email'] = email;
				params['user_name'] = data.name;
			}
		}

		this.subscription.add(
			this.dataService.post(URLS.addComment, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.blogFormGroup.reset();
					this.commentPage = 1;
					this.getCommentList();
					this.toasterService.successToast(data['message'])
				}
				else {
					this.isLoading = false;
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	likeDislike(listData) {
		const email = this.commonService.localStorageGet('user_email') ? this.commonService.localStorageGet('user_email') : null
		if (listData.is_liked) {
			const params = {
				blog_comment_id: listData.blog_comment_id,
				email: email,
			}
			this.subscription.add(
				this.dataService.delete(URLS.dislikeComment, params).subscribe(data => {
					if (data['code'] == 200) {
						this.blogCommentList.forEach(element => {
							if (element.blog_comment_id == listData.blog_comment_id) {
								element['is_liked'] = 0;
								element['like_count'] = --element['like_count']
							}
						});
					}
					else {
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)

		} else {
			const params = {
				blog_comment_id: listData.blog_comment_id,
				email: email,
			}
			this.subscription.add(
				this.dataService.post(URLS.likeComment, params).subscribe(data => {
					if (data['code'] == 200) {
						this.blogCommentList.forEach(element => {
							if (element.blog_comment_id == listData.blog_comment_id) {
								element['is_liked'] = 1
								element['like_count'] = ++element['like_count']
							}
						});
					}
					else {
						this.isLoading = false;
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}
	}

	initializeBlogFromGroup() {
		return this.formBuilder.group({
			email: new FormControl(null, {
				validators: Validators.compose([]),
			}),
			comment: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			})
		})
	}

}
