<div class="my-earnings">


	<ion-modal #modal id="filtermodal" trigger="open-modal" [presentingElement]=""
		*ngIf="!getPlatformService.isDesktop">
		<ng-template>
			<ion-header>
				<ion-toolbar>
					<ion-title>Filters</ion-title>
					<ion-buttons slot="end">
						<ion-button (click)="closeFilterModal()"><img alt="Close Icon" src="/assets/icon/close.svg"
								alt="" slot="end" class="close-icon"></ion-button>
					</ion-buttons>
				</ion-toolbar>
			</ion-header>
			<ion-content>
				<ion-row class="p-10 ">
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="pr-20 search-col">
						<div class="width-100">
							<ion-input [(ngModel)]="searchText" (keyup.enter)="search()" type="text"
								placeholder="Search By Name"></ion-input>
						</div>
					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<div class="d-flex">
							<div id="fromDateId" class="pr-25">
								<ion-input placeholder="From Date" value="{{ fromDate | date: 'MMM dd, YYYY' }}">
									<img alt="Calander Icon" class="input-icon" src="assets/icon/calender.svg">
								</ion-input>
							</div>
							<ion-popover trigger="fromDateId" id="datePopover1">
								<ng-template>
									<ion-datetime presentation="date" (ngModelChange)="onDateChange(fromdate)"
										[max]="toDate ? toDate : maxDate" [(ngModel)]="fromDate"
										#fromdate></ion-datetime>
								</ng-template>
							</ion-popover>
							<div id="toDateId" class="pr-25">
								<ion-input placeholder="To Date" value="{{ toDate | date: 'MMM dd, YYYY' }}">
									<img alt="Calender Icon" class="input-icon" src="assets/icon/calender.svg">
								</ion-input>
							</div>
							<ion-popover trigger="toDateId" id="datePopover">
								<ng-template>
									<ion-datetime presentation="date" [(ngModel)]="toDate" #todate [max]="maxDate"
										(ngModelChange)="onDateChange(todate)"></ion-datetime>
								</ng-template>
							</ion-popover>
						</div>
					</ion-col>
				</ion-row>
			</ion-content>
			<div class="filter-button-modal-container">
				<div class="apply-filter-modal-button">
					<button class="grey-btn align-center p-15 ion-activatable ripple-parent" (click)="onSearch()"
						[ngClass]="!getPlatformService.isDesktop ? 'width-100':null">
						<ion-icon name="filter-outline"></ion-icon>
						<span *ngIf="!getPlatformService.isDesktop" class="ml-10">
							Apply</span><ion-ripple-effect></ion-ripple-effect>
					</button>
				</div>
				<div class="cursor-pointer clear-fliter-modal-button ml-10" (click)="closeFilter()">
					<button class="grey-btn" (click)="closeFilter()"
						[ngClass]="!getPlatformService.isDesktop ? 'width-100':null">
						<ion-icon name="close-outline"></ion-icon>
						<span *ngIf="!getPlatformService.isDesktop" class="ml-10">Clear</span>
					</button>

				</div>

			</div>
		</ng-template>
	</ion-modal>

	<ion-row class="mb-15" *ngIf="!getPlatformService.isDesktop">
		<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
			<div class="header-container">
				<div class="btn-start">
					<div class=" flex space-between">
						<button id="open-modal"
							[ngClass]="showFilter && getPlatformService.isDesktop? 'filter-btn-blue':'filter-btn-grey'"
							class=" align-center" (click)="filterShowButton()">
							<img alt="Fliter Icon"
								[ngClass]="showFilter  && getPlatformService.isDesktop?'rotate-90':''"
								[src]="showFilter  && getPlatformService.isDesktop ? '/assets/icon/filter_white.svg':'/assets/icon/filter_grey.svg'">
							<div class="filter-text ml-15">
								Filter
							</div>
						</button>
					</div>
				</div>
			</div>
		</ion-col>
	</ion-row>


	<ion-row class="mb-15">
		<ion-col size="12" size-xs="12" size-sm="6">
			<div class="total-earnings-container mr-15">
				<div class="total-earnings mb-10">
					Total Earnings
				</div>
				<div class="amount">
					{{commonService.currencySymbol}} {{ total_earning | indianCurrency }}
				</div>
			</div>

		</ion-col>
		<ion-col size="12" size-xs="12" size-sm="6">
			<div class="total-receivable-container d-flex">
				<div>
					<div class="total-receivable mb-10">
						Total Receivable Amount
					</div>
					<div class="amount">
						{{commonService.currencySymbol}} {{ receivable_amount | indianCurrency }}
					</div>
				</div>
				<div class="request-payout-button-container">
					<button class="submit-btn w-154 pl-20 pr-20" (click)="payoutRequest()"
						[disabled]="isLoading || receivable_amount < 1">
						<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
						Request Payout
					</button>
				</div>
			</div>
		</ion-col>
	</ion-row>
	<ion-row *ngIf="getPlatformService.isDesktop">
		<ion-col size="12" size-lg="5.5" class="pr-20 search-col">
			<div class="width-100">
				<ion-input [(ngModel)]="searchText" (keyup.enter)="search()" type="text"
					placeholder="Search By Name"></ion-input>
			</div>
		</ion-col>
		<ion-col size="12" size-lg="6.5">
			<div class="d-flex">
				<div id="fromDateId" class="pr-25">
					<ion-input placeholder="From Date" value="{{ fromDate | date: 'MMM dd, YYYY' }}">
						<img alt="Calander Icon" class="input-icon" src="assets/icon/calender.svg">
					</ion-input>
				</div>
				<ion-popover trigger="fromDateId" id="datePopover1">
					<ng-template>
						<ion-datetime presentation="date" (ngModelChange)="onDateChange(fromdate)"
							[max]="toDate ? toDate : maxDate" [(ngModel)]="fromDate" #fromdate></ion-datetime>
					</ng-template>
				</ion-popover>
				<div id="toDateId" class="pr-25">
					<ion-input placeholder="To Date" value="{{ toDate | date: 'MMM dd, YYYY' }}">
						<img alt="Calender Icon" class="input-icon" src="assets/icon/calender.svg">
					</ion-input>
				</div>
				<ion-popover trigger="toDateId" id="datePopover">
					<ng-template>
						<ion-datetime presentation="date" [(ngModel)]="toDate" #todate [max]="maxDate"
							(ngModelChange)="onDateChange(todate)"></ion-datetime>
					</ng-template>
				</ion-popover>
				<div class="pr-25 search-icon-container">
					<button class="grey-search-btn align-center p-15" (click)="onSearch()">
						<img alt="Search" src="/assets/icon/search.svg" class="grey-icon-btn">
						<span *ngIf="!getPlatformService.isDesktop" class="ml-10">Search</span>
					</button>
				</div>
				<div class="cross-icon-container">
					<div class="p-5 cursor-pointer" (click)="closeFilter()">
						<img alt="Close Icon" src="/assets/icon/close.svg" alt="" slot="end" class="close-icon">
					</div>
				</div>
			</div>
		</ion-col>
	</ion-row>
	<ng-container>
		<ion-row>
			<ion-col size="12">
				<div class="list mt-30">
					<app-table-listing [totalCount]="totalCount" [settings]="column_settings" [pageType]="'my_earnings'"
						[widthConfig]="widthConfig" [listOfAllData]="listOfAllData" (sortByEvent)="getListData($event)"
						[isLoading]="isEarning" [isStickyColumn]="false"></app-table-listing>
				</div>
			</ion-col>
		</ion-row>
		<ion-row class="pagination-div">
			<ion-col size="12" class="pagination-col return-orders-pagination-col">
				<div class="pagination-wrapper" *ngIf="listOfAllData && listOfAllData.length > 0" [ngClass]="[totalCount < 10 && getPlatformService.isDesktop ?'pagination-justify-content-end':totalCount < 10 &&
                    !getPlatformService.isDesktop? 'pagination-content-start' :'']">
					<ng-container *ngIf="totalCount > 10">
						<div class="items">
							Items
							<ng-select [searchable]="false" (change)="changeListItem($event)"
								[(ngModel)]="paginationVal" [clearable]="false" class="pagination"
								[items]="commonService.paginationList" bindLabel="label" bindValue="value"
								[searchable]="false" [editable]="false" [searchFn]="false">
							</ng-select>
							<ion-label class="perpage-title">of {{totalCount}}</ion-label>
						</div>
					</ng-container>
					<div>
						<ion-row class="pagination-div">
							<ion-col size="12" class="pagination-col return-orders-pagination-col">
								<app-tile class="grid-tile" *ngFor="let post of listOfAllData | paginate :
                                      { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount };
                                      let i = index" [current]="post"></app-tile>
								<pagination-controls [maxSize]="5" class="paginator" previousLabel="" nextLabel=""
									(pageChange)="onChange($event)"></pagination-controls>
							</ion-col>
						</ion-row>
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ng-container>
</div>