import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
	selector: 'app-order-warehouse-modal',
	templateUrl: './order-warehouse-modal.component.html',
	styleUrls: ['./order-warehouse-modal.component.scss'],
})
export class OrderWarehouseModalComponent implements OnInit {
	@Input() submitBtnText: any;
	@Input() submitBtnLink?: any;
	@Input() company_id?: number;
	@Input() order_item_id?: number;
	@Input() warehouseAddress?: string;
	maxDate: any;
	warehouseId
	todaysDate = new Date();
	isSubmitButtonClicked: boolean = false;
	public warehouseformGroup: FormGroup;

	public validation_messages = {
		warehouse_address: [
			{ type: 'required', message: 'Please select a dispatch location' },
		]
	}
	orderWareHouseData: any = []
	public subscription = new Subscription();
	orderDetails: any;
	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		public dataService: ApiService,
		public commonServie: CommonService,
		public toasterService: ToasterService,
		private date: DatePipe, private formBuilder: FormBuilder,
	) { }

	ngOnInit() {
		this.maxDate = this.date.transform(this.todaysDate, 'YYYY-MM-dd');
		this.warehouseformGroup = this.initializeForm();
		this.getWareHouseDetail();
		this.getOrderItemDetail()
	}

	initializeForm() {
		return this.formBuilder.group({
			warehouse_address: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			})
		});
	}
	close(isDraft) {
		this.modalService.dismissModal({ isDraft });
	}

	get f1() { return this.warehouseformGroup.controls; }

	submitWareHouseDetail(isDraft) {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}
		this.f1.warehouse_address.updateValueAndValidity();
		if (this.warehouseformGroup.invalid && !this.warehouseAddress) {
			return;
		}
		this.isSubmitButtonClicked = true;
		let param = {
			order_item_id: this.order_item_id,
			dispatched_from_warehouse_id: this.orderDetails.dispatched_from_warehouse_id ? this.orderDetails.dispatched_from_warehouse_id : this.warehouseId,
			estimated_delivery_date: this.date.transform(this.orderDetails.estimated_delivery_date, 'YYYY-MM-dd')
		}
		this.subscription.add(
			this.dataService.put(URLS.updateSellerManageOrderDetails, param).subscribe(data => {
				if (data['code'] == 200) {
					this.isSubmitButtonClicked = false;
					this.modalService.dismissModal({ flag: true, estimated_delivery_date: param['estimated_delivery_date'] });
				}
				else {
					this.isSubmitButtonClicked = false;
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isSubmitButtonClicked = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	onDateChange(datetime) {
		// datetime.cancel(true);
	}

	getWareHouseDetail() {
		let company_id = this.commonServie.localStorageGet('company_id')
		let param = {
			company_id: company_id
		}
		if (!this.warehouseAddress) {
			this.subscription.add(
				this.dataService.get(URLS.sellerManageOrderWarehouseAddress, param).subscribe(data => {
					if (data['code'] == 200) {
						this.orderWareHouseData = data['data'];
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
				}, error => {
					// this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}
	}

	getOrderItemDetail() {
		let param = {
			"order_item_id": this.order_item_id
		};
		this.subscription.add(
			this.dataService.get(URLS.sellerManageOrderDetails, param).subscribe(data => {
				if (data['code'] == 200) {
					this.orderDetails = data['data'];
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}
}
