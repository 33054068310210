import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { URLS } from '../_config/api.config';
import { ApiService } from './api-data.service';
import { ToasterService } from './toaster.service';

@Injectable({
	providedIn: 'root'
})
export class CartService {
	public subscription = new Subscription();
	public cartCount = 0;
	constructor(
		private toasterService: ToasterService,
		private dataService: ApiService,
	) { }


	addToCart(product_id, price_per_unit, seller_id, seller_product_id, product_brand_mapping_id, product_quantity, product_package_mapping_id, shipping_address_id, warehouse_id, km_distance) {
		const params = {
			"product_id": +product_id,
			"seller_id": +seller_id,
			"seller_product_id": +seller_product_id,
			"price_per_unit": price_per_unit,
			"quantity": parseInt(product_quantity),
			"product_brand_mapping_id": +product_brand_mapping_id,
			"shipping_address_id": +shipping_address_id,
			"product_package_mapping_id": +product_package_mapping_id ? product_package_mapping_id : null,
			"warehouse_id": warehouse_id,
			"km_distance": km_distance ? km_distance : 0
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.addToCart, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.toasterService.successToast(data['message'], 'top');
						setTimeout(() => {
							this.getCartList();
							this.cartCount++;
						}, 400)

						resolve(data);
					} else {
						this.toasterService.errorToast(data['message'], 'top');
						reject();
					}
				}, error => {
					if (error['code'] != 422) {
						this.toasterService.errorToast(error['message'], 'top');
					}
					reject(error);
				})
			)
		});
	}

	getCartList() {
		if (localStorage.getItem('accessToken')) {
			return new Promise((resolve, reject) => {
				this.subscription.add(
					this.dataService.get(URLS.cartList).subscribe(data => {
						if (data && data['code'] == 200) {
							let cartData = data['data'];
							this.cartCount = cartData['quotableProduct'].length + cartData['directPurchased'].length;
							resolve(cartData);
						} else {
							this.toasterService.errorToast(data['message'], 'top');
							reject();
						}
					}, error => {
						this.toasterService.errorToast(error['message'], 'top');
						reject(error);
					})
				)
			})
		}

	}

	deleteCartItem(params) {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.delete(URLS.cartDelete, params).subscribe(data => {
					if (data && data['code'] == 200) {
						resolve(data);
					} else {
						this.toasterService.errorToast(data['message'], 'top');
						reject();
					}
				}, error => {
					this.toasterService.errorToast(error['message'], 'top');
					reject(error);
				})
			)
		});
	}

	clearCart() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.delete(URLS.clearCart).subscribe(data => {
					if (data && data['code'] == 200) {
						this.getCartList();
						this.cartCount = 0;
						resolve(data);
					} else {
						this.toasterService.errorToast(data['message'], 'top');
						reject();
					}
				}, error => {
					this.toasterService.errorToast(error['message'], 'top');
					reject(error);
				})
			)
		});
	}
}
