import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/services/api-data.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ModalService } from '../../services/modal.service';

export interface OrderSummary {
	items_sub_total?: number;
	gst_total_amount?: number;
	items_total_amount?: number;
}

export interface Item {
	product_id?: number;
	product_name?: string;
	description?: null;
	category_name?: string;
	package_value?: string;
	unit_id?: number;
	unit_name?: string;
	unit_type?: string;
	default_image_url?: null;
	cgst_tax_percentage?: number;
	sgst_tax_percentage?: number;
	total_tax_percentage?: number;
	product_attributes?: ProductAttribute[];
	main_product_id?: number;
	seller_product_id?: number;
	sub_product_id?: number;
	sku?: string;
	seller_id?: number;
	warehouse_id?: number;
	price?: number;
	quantity?: number;
	sub_total?: number;
	gst?: number;
	total?: number;
}

export interface ProductAttribute {
	product_attribute_id?: number;
	attribute_id?: number;
	attribute_name?: null | string;
	attribute_type?: null | string;
	product_attributes_values?: ProductAttributesValue[];
}

export interface ProductAttributesValue {
	value_id?: number;
	value?: string;
}

@Component({
	selector: 'app-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
	@Input() items: any[] = [];
	@Input() subTotal = 0;
	@Input() originalPrice?: any;
	@Input() disabledBtn = false;
	@Input() isCheckout = false;
	@Input() address = [];
	@Input() title = 'Order Summary';
	@Input() isOrderDetail = false;
	orderDetails: any = {};
	orderSummaryList: Item[] = [];
	isLoading = true;
	public subscription = new Subscription();
	latitude;
	longitude;
	shipping_state_id;

	constructor(
		public commonService: CommonService,
		private navCtrl: NavController,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private cartService: CartService,
		private alertController: AlertController,
		private router: Router,
		private modalService: ModalService
	) {
		this.subscription = this.commonService.addressLatLong.subscribe(res => {
			if (res['data']) {
				this.latitude = res['data']['latitude'];
				this.longitude = res['data']['longitude'];
				if (res['data']['state_id']) {
					this.shipping_state_id = res['data']['state_id']
				}
				this.getCardIds();
			}
		});

	}

	ngOnInit() {
		if (this.isCheckout) {
			this.getCardIds();
		}

	}


	checkout() {
		this.commonService.localStorageRemove('isBuyNow');
		this.commonService.localStorageRemove('buyNowProduct');
		this.navCtrl.navigateRoot(['/home/checkout']);
	}

	getCardIds() {
		this.cartService.getCartList().then((data) => {
			if (data) {
				let qouteList = data['quotableProduct'] && data['quotableProduct'].length > 0 ? data['quotableProduct'] : [];
				let buyItems = data['directPurchased'] && data['directPurchased'].length > 0 ? data['directPurchased'] : [];
				let productData: any = [];
				qouteList.forEach(element => {
					let localQuotes = {};
					localQuotes['main_product_id'] = element['product_id'];
					localQuotes['seller_product_id'] = element['seller_product_id'];
					localQuotes['sub_product_id'] = element['sub_product_id'];
					localQuotes['quantity'] = element['quantity'];
					productData.push(localQuotes);

				});
				buyItems.forEach(element => {
					let localBuy = {};
					localBuy['main_product_id'] = element['product_id'];
					localBuy['seller_product_id'] = element['seller_product_id'];
					localBuy['sub_product_id'] = element['sub_product_id'];
					localBuy['quantity'] = element['quantity'];
					productData.push(localBuy);
				});
				if (productData.length > 0) this.getOrderSummaryProducts(productData)
			}

		}, error => {

			this.toasterService.errorToast(error, 'top');
		});
	}

	async deleteOrderPopup(id, index) {
		const alert = await this.alertController.create({
			header: 'Are you sure ? ',
			message: 'You want to delete this item from order summary ?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.deleteProduct(id, index);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	deleteProduct(id, index) {
		const params = {
			"seller_product_id": id,
		}
		this.cartService.deleteCartItem(params).then((data) => {
			if (data) {
				this.cartService.cartCount = this.cartService.cartCount - 1;
				this.orderSummaryList.splice(index, 1);
			}
		}, error => {
			this.toasterService.errorToast(error, 'top');
		});
	}

	getOrderSummaryProducts(ids) {
		let params: any = {};
		params = {
			"latitude": this.latitude ? this.latitude.toString() : null,
			"longitude": this.latitude ? this.longitude.toString() : null,
			"products": ids,
			"state_id": this.shipping_state_id
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.patch(URLS.orderSummaryDetails, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					if (data['data']) {
						this.orderSummaryList = data['data']['items'];
						this.orderDetails.gst_total_amount = data['data']['gst_total_amount'];
						this.subTotal = data['data']['items_total_amount'];
						this.orderDetails.items_sub_total = data['data']['items_sub_total'];
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	openModal(val) {
		val.isView = !val.isView;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
