import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { PaymentGatewayWaitingModalComponent } from 'src/app/payment-gateway-waiting-modal/payment-gateway-waiting-modal.component';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { environment } from 'src/environments/environment';
import { ProductDetailPageService } from '../../../app/services/product-detail-page.service';
import { AddQuoteComponent } from '../add-quote/add-quote.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
declare var EasebuzzCheckout: any; // Declare EasebuzzCheckout variable

export interface QuoteDetail {
	quote_id?: number;
	sub_product_id?: number;
	product_id?: number;
	quantity?: number;
	shipping_address_id?: number;
	radius?: number;
	timeframe?: number;
	is_credit_order?: boolean;
	credit_days?: number;
	is_active?: boolean;
	created_by_reference?: string;
	updated_by_reference?: null;
	created_by?: number;
	updated_by?: null;
	created_at?: string;
	updated_at?: string;
	product_image?: null;
	customer_name?: string;
	product_name?: string;
	sku?: string;
	shipping_address?: string;
	seller_count?: number;
	price_per_unit?: null;
	sub_total?: number;
	total_price?: number;
	total?: number;
	estimated_delivery_date?: string;
	status?: string;
	product_brand_mapping_id?: number;
	product_package_mapping_id?: number;
	best_deal_price?: number;
	product_attributes?: any[];
	unit_name?: string;
}


@Component({
	selector: 'app-quotes-detail',
	templateUrl: './quotes-detail.component.html',
	styleUrls: ['./quotes-detail.component.scss'],
})
export class QuotesDetailComponent implements OnInit {
	public subscription = new Subscription();
	isLoading = false;
	quoteDetail: QuoteDetail = {};
	quoteId = null;
	status;
	isManageQuote = false;
	url: any;
	apiUrl = '';
	userId = null;
	isManageUserQuote = false;
	savedAmount: number = 0

	constructor(
		public commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private router: Router,
		private navCtrl: NavController,
		public alertCtrl: AlertController,
		public getPlatformService: GetPlatformService,
		private locationService: LocationService,
		private modalService: ModalService,
		private productDetailPageService: ProductDetailPageService

	) { }

	ngOnInit() {
		this.isManageQuote = false;
		this.status = this.commonService.localStorageGet('status');
		if (this.router.url.includes("manage-quotes/")) {
			this.isManageQuote = true;
			this.isManageUserQuote = false;
		}
		else if (this.router.url.includes("user-quote-detail/")) {
			this.apiUrl = URLS.userQuotesRequestDetail;
			this.isManageUserQuote = true;
			this.userId = this.commonService.localStorageGet('enterprise_user_id');
		}
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.quoteId = params.get('id');
			if (this.quoteId) {
				this.getDetail();
			}
		});

	}

	getDetail() {
		let user = this.commonService.userData['isCustomer'] ? "CUSTOMER" : "SELLER"
		if (!this.isManageUserQuote) {
			if (user == 'CUSTOMER') {
				this.url = URLS.customerQuoteDetail
			} else {
				this.url = URLS.sellerQuoteDetail
			}
		}
		else {
			this.url = this.apiUrl
		}

		let param = {
			"quote_id": this.quoteId,
		}
		if (this.isManageUserQuote) {
			param['enterprise_user_id'] = this.userId;
			param['enterprise_user_type'] = this.commonService.userData.isCustomer ? 'CUSTOMER' : 'SELLER';
		}
		else {
			param['status'] = this.status;
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(this.url, param).subscribe(data => {
				if (data['code'] == 200) {
					this.quoteDetail = data['data'];
					this.quoteDetail['package_value'] = this.quoteDetail['package_value'] ? this.quoteDetail['package_value'] + " " + this.quoteDetail['unit_name'] : this.quoteDetail['unit_name']
					this.savedAmount = Math.abs(this.quoteDetail.best_deal_price - this.quoteDetail.sub_total)
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)

	}

	async updateQuote() {
		if (this.quoteDetail.status == 'SUBMITTED') {
			let param = {
				quote_id: this.quoteDetail.quote_id,
				is_active: false
			};
			const modal = await this.modalCtrl.create({
				component: ConfirmationModalComponent,
				id: "thankYouModal",
				backdropDismiss: true,
				componentProps: {
					description: 'Are You Sure You Want To Withdraw Quotation?',
					param: param,
					apiURL: URLS.quoteWithdrawl,
					link: '/home/account-details/manage-quotes'
				}
			});
			modal.present();
			modal.onWillDismiss().then((data) => {


			})

		}
		else if (this.quoteDetail.status == 'RECEIVED') {
			if (this.quoteDetail['is_quoted']) {
				this.toasterService.errorToast('You have already request for quote.');
				return;
			}
			const modal = await this.modalCtrl.create({
				component: AddQuoteComponent,
				id: "requestQuoteModal",
				backdropDismiss: true,
				componentProps: {
					quote_id: this.quoteDetail.quote_id,
					quote_details: this.quoteDetail,
					cgst_tax: this.quoteDetail['product_cgst_tax_percentage'],
					sgst_tax: this.quoteDetail['product_sgst_tax_percentage'],
					igst_tax: this.quoteDetail['product_igst_tax_percentage'],
				}
			});
			modal.present();
			modal.onWillDismiss().then((data) => {

			})
		}
	}

	redirectToDetail(data) {
		let categoryName = this.commonService.trimUrl(data.category_name);
		let productName = this.commonService.trimUrl(data.product_name);


		this.navCtrl.navigateRoot('/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
			{
				animated: false,
				"state": {
					"breadcrumb": data.category_name,
					"url": '/home/product-detail/' + categoryName + "/" + productName + "/" + data.product_id,
					"productDetail": true
				}
			});

	}

	async onReBid() {

		let param = {
			quote_id: this.quoteDetail.quote_id,
			is_active: this.quoteDetail.is_active,
			product_id: this.quoteDetail.product_id,
			image_url: this.quoteDetail.product_image,
			product_brand_mapping_id: this.quoteDetail.product_brand_mapping_id ? this.quoteDetail.product_brand_mapping_id : null,
			product_package_mapping_id: this.quoteDetail.product_package_mapping_id ? this.quoteDetail.product_package_mapping_id : null,
			sku: this.quoteDetail.product_name,
			quantity: this.quoteDetail.quantity,
			unit_name: this.quoteDetail.unit_name,
			address: this.quoteDetail.shipping_address,
			address_id: this.quoteDetail.shipping_address_id ? this.quoteDetail.shipping_address_id : null,
			best_deal_price: this.quoteDetail.best_deal_price ? this.quoteDetail.best_deal_price : 0
		};

		if (this.quoteDetail.product_attributes) {
			param['product_attributes'] = this.quoteDetail.product_attributes ? this.quoteDetail.product_attributes : null
		}

		const modal = await this.modalCtrl.create({
			component: ConfirmationModalComponent,
			id: "thankYouModal",
			backdropDismiss: true,
			componentProps: {
				description: 'Are You Sure You Want To Request Re-Bid?',
				subDescription: 'Your Current Best Deal Price Will Be Discarded Once You Apply For Re-Bid',
				param: param,
				apiURL: URLS.quoteWithdrawl,
				link: '/home/account-details/manage-quotes',
				isRebid: true
			}
		});
		modal.present();
		modal.onWillDismiss().then((data) => {
		})
	}

	closeRequest() {

		const param = {
			"quote_id": this.quoteDetail.quote_id,
			"is_active": false
		}

		this.subscription.add(
			this.dataService.put(URLS.closeRequest, param).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					let tabValue = "CLOSED"
					this.navCtrl.navigateForward("/home/account-details/my-quote-requests", { state: { tabVal: tabValue } });
					this.toasterService.successToast(data['message']);
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	async onClose() {
		const alert = await this.alertCtrl.create({
			header: 'Are you sure ? ',
			message: "You want to close request",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.closeRequest();
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	goToProductDetail() {
		let data = this.quoteDetail
		this.productDetailPageService.goToProductDetail(data)
	}

	isPlaceOrderBtnDisable = false
	async placeOrderConfirmation(data) {
		const alert = await this.alertCtrl.create({
			header: 'Are you sure? ',
			message: "You want to place an order?",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.placeOrder(data);
						this.isPlaceOrderBtnDisable = true;
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}


	placeOrder(val) {
		const params = {
			"quote_id": val.quote_id,
			"quote_bid_id": val.quote_bid_id,
			"seller_id": val.seller_id
		}
		this.subscription.add(
			this.dataService.post(URLS.placeQuoteOrder, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						let tabValue = "ORDER_INITIATED";

						let paymentObject = {
							'order_id': data['data'].order_id,
							'sub_total': parseFloat(data['data']['total_price']),
							'product_id': data['data']['product_id']
						}

						let obj = {
							component: PaymentGatewayWaitingModalComponent,
							backdropDismiss: false,
							canDismiss: true,
							cssClass: 'success-modal',
						};
						this.modalService.openModal(obj);
						setTimeout(() => {
							this.modalCtrl.dismiss();
							this.paymentAPIMethodCall(paymentObject);
						}, 3000)
						this.commonService.quoteListingData(tabValue);
						this.toasterService.successToast(data['message']);


					}
				}
				else {
					this.toasterService.errorToast(data['message']);
					this.isPlaceOrderBtnDisable = false;
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
				this.isPlaceOrderBtnDisable = false;

			})
		)
	}

	paymentAPIMethodCall(data?) {
		let params = {
			"order_id": data.order_id,
			"productinfo": data.product_id.toString(),
		}

		this.subscription.add(
			this.dataService.post(URLS.paymentKey, params).subscribe(response => {
				if (response['code'] == 200) {
					const easebuzzCheckout = new EasebuzzCheckout(environment.paymentKey, environment.paymentMode);
					const options = {
						access_key: response.data['data'],
						onResponse: (response: any) => {
							if (response.status == "success") {
								this.toasterService.successToast(response['error_Message']);
								let obj = {
									component: PaymentGatewayWaitingModalComponent,
									backdropDismiss: false,
									canDismiss: true,
									cssClass: 'success-modal',
								};
								this.modalService.openModal(obj);
								setTimeout(() => {
									this.modalCtrl.dismiss();
									this.router.navigate(['/home/account-details/my-orders']);
									this.commonService.setEvent("paymentRefresh", null)
								}, 3000)

							}
							else if (response.status == "userCancelled") {
								this.toasterService.errorToast("User Cancelled Payment");
								let obj = {
									component: PaymentGatewayWaitingModalComponent,
									backdropDismiss: false,
									canDismiss: true,
									cssClass: 'success-modal',
								};
								this.modalService.openModal(obj);
								setTimeout(() => {
									this.modalCtrl.dismiss();
									this.router.navigate(['/home/account-details/my-orders']);
								}, 3000)
							}
							else if (
								response.status == "pending" ||
								response.status == "failure" ||
								response.status == "dropped" ||
								response.status == "bounced"
							) {
								this.toasterService.errorToast("Payment " + response.status);
								let obj = {
									component: PaymentGatewayWaitingModalComponent,
									backdropDismiss: false,
									canDismiss: true,
									cssClass: 'success-modal',
								};
								this.modalService.openModal(obj);
								setTimeout(() => {
									this.modalCtrl.dismiss();
									this.router.navigate(['/home/account-details/my-orders']);
								}, 3000)

							}
							else {
								this.toasterService.errorToast("Payment " + response.status);
								this.isPlaceOrderBtnDisable = false;

							}
						},
						onclose: (response: any) => { console.log('close') },
						oncancel: (response: any) => { console.log('cancel') },

						theme: '#123456' // color hex
					};

					easebuzzCheckout.initiatePayment(options);
				}
				else {
					this.modalCtrl.dismiss()
					this.isPlaceOrderBtnDisable = false;

					this.toasterService.errorToast(response['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.isPlaceOrderBtnDisable = false;

				this.modalCtrl.dismiss()
				this.toasterService.errorToast(error['message']);

			})
		)
	}


}
