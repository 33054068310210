import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	isLoading = false;
	public desktopView: boolean = false;
	public subscription = new Subscription();
	message: string = "";
	approvalText: string = "";
	fromPage: any;

	public validation_messages = {
		email: [
			{ type: 'required', message: 'Please enter an email' },
			{ type: 'invalidEmail', message: 'Please enter a valid email' }
		]
	}

	forgotPassFromGroup: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		private navCtrl: NavController,
		private toasterService: ToasterService,
		private dataService: ApiService,
		private platform: Platform,

	) { }

	ngOnInit() {
		this.forgotPassFromGroup = this.initializeforgotPassFromGroup();
	}


	initializeforgotPassFromGroup() {

		return this.formBuilder.group({
			email: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailPhoneValidator]),
			})
		})
	}

	send() {
		for (const i in this.forgotPassFromGroup.controls) {
			this.forgotPassFromGroup.controls[i].markAsDirty();
			this.forgotPassFromGroup.controls[i].updateValueAndValidity();
			this.forgotPassFromGroup.controls[i].markAsTouched();
		}
		if (this.forgotPassFromGroup.invalid) {
			return
		}

		const params = {
			"user_name": this.forgotPassFromGroup.controls.email.value,
			"platform": this.platform.is("ios") && this.platform.is("android") ? "MOBILE" : "WEB",
		}
		this.isLoading = true;

		this.subscription.add(
			this.dataService.post(URLS.forgotPassword, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					let forgotPasswordData = data['data'];
					if (forgotPasswordData) {
						this.commonService.localStorageSet('uuid', forgotPasswordData.uuid);
						let userData = forgotPasswordData;
						let encryptedUserDetails = this.commonService.encryptData(JSON.stringify(userData));
						this.commonService.localStorageSet('user_details', encryptedUserDetails);
						this.commonService.localStorageSet("forgot_password_details", params.user_name)
						this.toasterService.successToast(data['message'], 'top');
						this.forgotPassFromGroup.reset();
						this.fromPage = 'forgotPassword';
						this.navCtrl.navigateForward('/two-factor-authentication', { queryParams: { fromPage: this.fromPage } });
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	back() {
		this.navCtrl.navigateRoot(['/login']);
	}
}
