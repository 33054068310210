<div class="dashboard-container" *ngIf="commonService.userData.isSeller">
	<ng-container *ngIf="!isLoading">
		<ion-row>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-blue">
					<div class="card-label">
						Total Number of Orders
					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData?.totalOrder ? dashboardData?.totalOrder:'0'}}
						</div>

					</div>
					<div class="cards">
						<span class="fw-500"> Active </span>- {{dashboardData?.totalActiveOrder ?
						dashboardData?.totalActiveOrder:'0'}} |
						<span class="fw-500"> Completed</span> - {{dashboardData?.totalCompletedOrder ?
						dashboardData?.totalCompletedOrder:'0'}} |
						<span class="fw-500"> Returned</span> - {{dashboardData?.totalReturnedOrder ?
						dashboardData?.totalReturnedOrder:'0'}}
					</div>
					<div class="view-all" *ngIf="isSellerApproved" (click)="redirectPage('Order')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-orange">
					<div class="card-label">
						Total Number of Products
					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData?.totalProduct ?
							dashboardData?.totalProduct:'0'}}
						</div>
					</div>
					<div class="cards">
						<span class="fw-500"> Active </span> - {{dashboardData?.activeProduct ?
						dashboardData?.activeProduct:'0'}} |
						<span class="fw-500"> InActive</span> - {{dashboardData?.inactiveProduct ?
						dashboardData?.inactiveProduct:'0'}} |
						<span class="fw-500"> Live </span> - {{dashboardData?.operationalAreaCount > 0 &&
						dashboardData?.liveProduct ?
						dashboardData?.liveProduct:'0'}} |
						<span class="fw-500"> Un-mapped</span> - {{dashboardData?.operationalAreaCount > 0 &&
						dashboardData?.unMappedProduct ?
						dashboardData?.unMappedProduct:'0'}}

					</div>
					<div class="view-all" *ngIf="isSellerApproved" (click)="redirectPage('Product')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>

			<ion-col *ngIf="commonService.is_admin || isAdmin" size-xs="12" size-sm="12" size-md="6" size-lg="6"
				size-xl="6">
				<ion-card class="card bg-grey">
					<div class="card-label">
						Total Number of Sub-users
					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData?.totalSubUser ? dashboardData?.totalSubUser:'0'}}

						</div>
					</div>
					<div class="cards">
						<span class="fw-500"> Active </span> - {{dashboardData?.activeSubUser ?
						dashboardData?.activeSubUser:'0'}} |
						<span class="fw-500"> InActive</span> - {{dashboardData?.inactiveSubUser ?
						dashboardData?.inactiveSubUser:'0'}}

					</div>
					<div class="view-all" *ngIf="isSellerApproved" (click)="redirectPage('Sub_User')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-yellow total-earning-card">
					<div class="card-label">
						Total Earnings
					</div>
					<div class="justify-content-between card-data-container">
						<div class="value">
							{{dashboardData?.totalEarning ? dashboardData?.totalEarning:'0'}}

						</div>
						<div class="view-all" *ngIf="isSellerApproved" (click)="redirectPage('Earning')">
							<span>
								View all
							</span>
						</div>
					</div>
				</ion-card>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-green">
					<div class="card-label">
						Total Number of Quotes
					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData?.totalQuotes ?
							dashboardData?.totalQuotes:'0'}}
						</div>
					</div>
					<div class="cards">
						<span class="fw-500"> Received </span>- {{dashboardData?.receivedQuote ?
						dashboardData?.receivedQuote:'0'}} |
						<span class="fw-500"> Submitted</span> - {{dashboardData?.submittedQuote ?
						dashboardData?.submittedQuote:'0'}} |
						<span class="fw-500"> Order Initiated (Won) </span>- {{dashboardData?.orderInitiatedQuote ?
						dashboardData?.orderInitiatedQuote:'0'}} |
						<span class="fw-500"> Expired</span> - {{dashboardData?.expiredQuote ?
						dashboardData?.expiredQuote:'0'}} |
						<span class="fw-500"> Closed </span>- {{dashboardData?.closedQuote ?
						dashboardData?.closedQuote:'0'}}
					</div>
					<div class="view-all" *ngIf="isSellerApproved" (click)="redirectPage('Quote')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>
		</ion-row>
		<ion-row>
			<div class="list mt-30">
				<div class="heading-label mb-10">Recent Orders</div>
				<app-table-listing [totalCount]="totalCount" [pageType]="'manage_quotes'" [settings]="column_settings"
					[isStickyStatusActionColumn]="getPlatformService.isDesktop ? true : false" [isLoading]="isLoading"
					[widthConfig]="widthConfig" [listOfAllData]="recentOrders" [pageIndex]="pageNo"></app-table-listing>

			</div>
		</ion-row>
	</ng-container>
	<ng-container *ngIf="isLoading">
		<app-loader></app-loader>
	</ng-container>
</div>
<div class="dashboard-container" *ngIf="!commonService.userData.isSeller">
	<ng-container *ngIf="!isLoading">
		<ion-row>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-blue">
					<div class="card-label">
						Total Number of Quotes
					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData && dashboardData.totalQuotes ? dashboardData.totalQuotes:'0'}}
						</div>

					</div>
					<div class="cards">
						<span class="fw-500">Requested </span>- {{dashboardData && dashboardData.requestedQuote ?
						dashboardData?.requestedQuote:'0'}} |
						<span class="fw-500"> Received </span> - {{dashboardData && dashboardData.receivedQuote ?
						dashboardData?.receivedQuote:'0'}} |
						<span class="fw-500"> Order Initiated </span> - {{dashboardData &&
						dashboardData.orderInitiatedQuote ?
						dashboardData?.orderInitiatedQuote:'0'}} |
						<span class="fw-500"> Expired </span> - {{dashboardData && dashboardData.expiredQuote ?
						dashboardData?.expiredQuote:'0'}} |
						<span class="fw-500"> Closed </span> - {{dashboardData && dashboardData.closedQuote ?
						dashboardData?.closedQuote:'0'}}
					</div>
					<div class="view-all" *ngIf="isBuyerApproved" (click)="redirectPage('My_Quote')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>
			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-orange">
					<div class="card-label">
						Total Number of Orders
					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData && dashboardData?.totalOrder ?
							dashboardData?.totalOrder:'0'}}
						</div>
					</div>
					<div class="cards">
						<span class="fw-500">Active </span>- {{dashboardData && dashboardData.totalActiveOrder ?
						dashboardData?.totalActiveOrder:'0'}} |
						<span class="fw-500">Completed </span>- {{dashboardData &&
						dashboardData.totalCompletedOrder ?
						dashboardData?.totalCompletedOrder:'0'}} |
						<span class="fw-500">Returned </span>- {{dashboardData && dashboardData.totalReturnedOrder
						?
						dashboardData?.totalReturnedOrder:'0'}}
					</div>
					<div class="view-all" *ngIf="isBuyerApproved" (click)="redirectPage('My_Order')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>

			<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
				<ion-card class="card bg-grey">
					<div class="card-label">
						Total Number of Payments

					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData && dashboardData.totalPaymentStatus ? dashboardData.totalPaymentStatus:'0'}}

						</div>
					</div>
					<div class="cards">
						<span class="fw-500"> Paid </span>- {{dashboardData &&
						dashboardData.paymentPaidOrder ?
						dashboardData?.paymentPaidOrder:'0'}} |
						<span class="fw-500"> Pending </span>- {{dashboardData &&
						dashboardData.paymentPendingOrder ?
						dashboardData?.paymentPendingOrder:'0'}} |
						<span class="fw-500">Rejected </span>- {{dashboardData &&
						dashboardData.paymentRejectedOrder ?
						dashboardData?.paymentRejectedOrder:'0'}} |
						<span class="fw-500"> Pending Verification </span>- {{dashboardData &&
						dashboardData.paymentPendingVerificationdOrder ?
						dashboardData?.paymentPendingVerificationdOrder:'0'}}
					</div>
					<div class="view-all" *ngIf="isBuyerApproved" (click)="redirectPage()">
						<!-- <span>
                            View all
                        </span> -->
					</div>
				</ion-card>
			</ion-col>
			<ion-col *ngIf="commonService.is_admin || isAdmin" size-xs="12" size-sm="12" size-md="6" size-lg="6"
				size-xl="6">
				<ion-card class="card bg-grey">
					<div class="card-label">
						Total Number of Sub-users

					</div>
					<div class="h-30 justify-content-between card-data-container">
						<div class="value">
							{{dashboardData?.totalSubUser ? dashboardData?.totalSubUser:'0'}}

						</div>
					</div>
					<div class="cards">
						<span class="fw-500"> Active </span> - {{dashboardData?.activeSubUser ?
						dashboardData?.activeSubUser:'0'}} |
						<span class="fw-500"> InActive</span> - {{dashboardData?.inactiveSubUser ?
						dashboardData?.inactiveSubUser:'0'}}

					</div>
					<div class="view-all" *ngIf="isBuyerApproved" (click)="redirectPage('Sub_User')">
						<span>
							View all
						</span>
					</div>
				</ion-card>
			</ion-col>
		</ion-row>
		<ion-row>
			<div class="list mt-30">
				<div class="heading-label mb-10">Recent Orders</div>
				<app-table-listing [totalCount]="totalCount" [pageType]="'manage_quotes'" [settings]="column_settings"
					[isStickyStatusActionColumn]="getPlatformService.isDesktop ? true : false"
					[widthConfig]="widthConfig" [listOfAllData]="recentOrders" [pageIndex]="pageNo"></app-table-listing>

			</div>
		</ion-row>
	</ng-container>
	<ng-container *ngIf="isLoading">
		<app-loader></app-loader>
	</ng-container>
</div>