import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/_common/customvalidators';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
	selector: 'app-e-way-modal',
	templateUrl: './e-way-modal.component.html',
	styleUrls: ['./e-way-modal.component.scss'],
})
export class EWayModalComponent implements OnInit {
	@Input() submitBtnText: any;
	@Input() submitBtnLink?: any;
	@Input() company_id?: number;
	@Input() order_item_id?: number;
	@Input() estimated_delivery_date?: number;
	@Input() warehouseAddress?: string;
	maxDate: any;
	warehouseId
	todaysDate = new Date();
	isSubmitButtonClicked: boolean = false;
	public ewayFormGroup: FormGroup;
	public warehouseformGroup: FormGroup;

	public validation_messages = {
		e_way_bill_no: [
			{ type: 'required', message: 'Please enter a e way bill no' },
			{ type: 'invalidString', message: 'Please enter a valid e way bill no' },
		],
		invoice_no: [
			{ type: 'required', message: 'Please enter a invoice number' },
			{ type: 'invalidString', message: 'Please enter a valid invoice number' },
		],
		warehouse_address: [
			{ type: 'required', message: 'Please select a dispatch location' },
		]
	}
	orderWareHouseData: any = []
	public subscription = new Subscription();
	orderDetails: any;
	constructor(
		private modalService: ModalService,
		public getPlatformService: GetPlatformService,
		public dataService: ApiService,
		public commonServie: CommonService,
		public toasterService: ToasterService,
		private date: DatePipe,
		private formBuilder: FormBuilder,
	) { }

	ngOnInit() {
		this.maxDate = this.date.transform(this.todaysDate, 'YYYY-MM-dd');
		this.ewayFormGroup = this.initializeForm();
		if (!this.warehouseAddress) {
			this.warehouseformGroup = this.initializeWarehouseForm();
		}
		this.getWareHouseDetail();
		this.getOrderItemDetail();
	}

	initializeForm() {
		return this.formBuilder.group({
			e_way_bill_no: new FormControl(null, {
				validators: Validators.compose([CustomValidator.blockSpecialCharacter, CustomValidator.noWhiteSpaceValidator]),
			}),
			invoice_no: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.blockSpecialCharacter, CustomValidator.noWhiteSpaceValidator]),
			})
		});
	}

	initializeWarehouseForm() {
		return this.formBuilder.group({
			warehouse_address: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			})
		});
	}
	close(isDraft) {
		this.modalService.dismissModal({ isDraft });
	}

	get f1() { return this.ewayFormGroup.controls; }

	get f2() { return this.warehouseformGroup.controls; }


	submitWareHouseDetail(isDraft) {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}

		if (!this.warehouseAddress) {
			this.f2.warehouse_address.markAsDirty();
			this.f2.warehouse_address.updateValueAndValidity();
			this.f2.warehouse_address.markAsTouched();
		}

		if (!this.warehouseAddress && this.warehouseformGroup.invalid) {
			return;
		}

		if (this.ewayFormGroup.invalid) {
			return;
		}
		this.isSubmitButtonClicked = true;

		let param = {
			order_item_id: this.order_item_id,
			order_status: "DISPATCHED",
			e_way_bill_no: this.f1.e_way_bill_no.value ? this.f1.e_way_bill_no.value : "",
			seller_invoice_no: this.f1.invoice_no.value,
			estimated_delivery_date: this.estimated_delivery_date,
			dispatched_from_warehouse_id: this.orderDetails.dispatched_from_warehouse_id ? this.orderDetails.dispatched_from_warehouse_id : this.warehouseId,
		}

		this.subscription.add(
			this.dataService.put(URLS.updateSellerManageOrderDetails, param).subscribe(data => {
				if (data['code'] == 200) {
					this.isSubmitButtonClicked = false;
					this.modalService.dismissModal({
						'dismiss': true,
						'ebill': this.f1.e_way_bill_no.value,
						'seller_invoice': this.f1.invoice_no.value
					});
				}
				else {
					this.isSubmitButtonClicked = false;
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isSubmitButtonClicked = false;
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	onDateChange(datetime) {
		// datetime.cancel(true);
	}

	getWareHouseDetail() {
		let company_id = this.commonServie.localStorageGet('company_id')
		let param = {
			company_id: company_id
		}
		if (!this.warehouseAddress) {
			this.subscription.add(
				this.dataService.get(URLS.sellerManageOrderWarehouseAddress, param).subscribe(data => {
					if (data['code'] == 200) {
						this.orderWareHouseData = data['data'];
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
				}, error => {
					// this.isLoading = false;
					this.toasterService.errorToast(error['message']);
				})
			)
		}
	}

	getOrderItemDetail() {
		let param = {
			"order_item_id": this.order_item_id
		};
		this.subscription.add(
			this.dataService.get(URLS.sellerManageOrderDetails, param).subscribe(data => {
				if (data['code'] == 200) {
					this.orderDetails = data['data'];

					if (this.orderDetails.total_price > 49999) {
						this.ewayFormGroup.get('e_way_bill_no').setValidators([Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.blockSpecialCharacter]);
						this.ewayFormGroup.get('e_way_bill_no').updateValueAndValidity();
					}
					else if (this.orderDetails.igst_tax_amount != null) {
						this.ewayFormGroup.get('e_way_bill_no').setValidators([Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.blockSpecialCharacter]);
						this.ewayFormGroup.get('e_way_bill_no').updateValueAndValidity();
					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}
}
