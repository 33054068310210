import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { profileTypeEnum } from '../../_common/enum.enum';
import { GetPlatformService } from '../../services/get-platform.service';
import { CategoriesComponent } from '../categories/categories.component';
import { CustomValidator } from './../../_common/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';

enum QuoteStatus {
	REQUESTED = "REQUESTED",
	RECEIVED = "RECEIVED",
	EXPIRED = "EXPIRED",
	ORDER_INITIATED = "ORDER INITIATED",
	CLOSED = "CLOSED",
	SUBMITTED = "SUBMITTED"
}

export interface AddUser {
	user_name?: string;
	email?: string;
	mobile_no?: string;
	access_rights?: number[];
	is_admin_user?: boolean;
	user_type?: string;
	is_active?: boolean;
	office_address_id?: number;
	enterprise_user_id?: number;
	designation?: string;
}
@Component({
	selector: 'app-add-user',
	templateUrl: './add-user.component.html',
	styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
	@ViewChild(CategoriesComponent) categoriesData;
	is_active = true;
	is_admin = false;
	companyInfo: any;
	userId: any;
	activeStatusDisabled: boolean = true;
	categoryNotChecked: boolean = false;
	company_id: any;
	userType: any;
	companyCategoryId: any = [];
	userForm: FormGroup;
	profileEnum = profileTypeEnum;
	selectedOfficeAddress: any;
	public isLoading = false;
	public subscription = new Subscription();
	pageNo = 1;
	pagesize = this.commonService.list;
	public itemsPerPage = this.commonService.list;
	public currentPage = 1;
	public enableNext: boolean = false;
	public totalCount: any;
	paginationVal = 10;
	showFilter = false;
	fromDate: any;
	toDate: any;
	searchValue: string;
	UserToggle = 'personal';
	fieldData = {
		"name": "User Name",
		"email": "Email",
		"mobile_no": "Phone number",
		"hideFirstName": true,
		"hideLastName": true
	}
	widthConfig = ['200px', '180px', '80px', '120px', '170px', '180px', '80px'];
	tabList = [
		{
			"name": 'Personal Details',
			"id": 1,
			"value": "personal"
		},
		{
			"name": 'Quote Requests',
			"id": 2,
			"value": "quotes"
		},

	]
	officeAddress: any = [];
	searchObj = {};
	searchText = '';
	column_settings = [
		{ primaryKey: 'request_on', header: 'Requested on' },
		{ primaryKey: 'product_name', header: 'Product name', image: true },
		{ primaryKey: 'qty', header: 'Qty' },
		{ primaryKey: 'timeframe', header: 'Timeframe' },
		{ primaryKey: 'quotation', header: 'Quotation price' },
		{ primaryKey: 'status', header: 'Status', status: true },
		{ primaryKey: '', header: 'Action', action: true }
	];
	listOfAllData = [];
	todaysDate = new Date();
	maxDate: any;
	public validation_messages = {
		office_address: [
			{ type: 'required', message: 'Please select office address' },
			{ type: 'invalidString', message: 'Please select valid office address' },

		],
		designation: [
			{ type: 'required', message: 'Please enter designation' },
			{ type: 'invalidString', message: 'Please enter valid designation' },
			{ type: 'maxlength', message: 'Maximum designation should be 50 character long' },
			{ type: 'invalidName', message: 'Please enter valid designation' },
		]
	}
	isQuoteList = true;
	constructor(
		private formBuilder: FormBuilder,
		private dataService: ApiService,
		private toasterService: ToasterService,
		public commonService: CommonService,
		private route: ActivatedRoute,
		private navCtrl: NavController,
		private datePipe: DatePipe,
		public getPlatformService: GetPlatformService,
	) { }

	ngOnInit() {
		if (this.commonService.userData.isSeller) {
			this.tabList = [
				{
					"name": 'Personal Details',
					"id": 1,
					"value": "personal"
				},
				{
					"name": 'Manage Quotes',
					"id": 2,
					"value": "quotes"
				},

			]
		}

		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.userForm = this.initializeFormGroup();
		this.getCompanyDetail().then((data) => {
			if (data) {
				this.getCompanyAddressList();
			}
		});
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.userId = params.get('id');
			if (this.userId) {
				this.activeStatusDisabled = false;
				this.getCompanyDetail().then((data) => {
					if (data) {
						this.getUsers();
					}
				});
			} else {
				this.activeStatusDisabled = true;
			}
		});

	}


	initializeFormGroup() {
		return this.formBuilder.group({
			user_name: new FormControl({ value: '', disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
			}),
			email: new FormControl({ value: '', disabled: false }, {
				validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
			}),
			phone_no: new FormControl({ value: '', disabled: false }, {
				validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
			}),
			office_address: new FormControl({ value: null, disabled: false }, {
				validators: Validators.compose([Validators.required]),
			}),
			designation: new FormControl({ value: '', disabled: false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.blockSpecialCharacter]),
			}),
		})
	}

	getCompanyDetail() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.sellerCompanyDetail).subscribe(data => {
					if (data['code'] == 200) {
						this.companyInfo = data['data'];
						this.company_id = this.companyInfo.company_id;
						this.userType = this.commonService.localStorageGet('user_type');
						resolve(data);
					}
					else {
						reject(data);
						this.toasterService.errorToast(data['message']);
					}
				}, error => {
					reject(error);
					this.toasterService.errorToast(error['message']);
				})
			)
		});
	}

	officeAddressChange(event) {
		this.selectedOfficeAddress = event.id;
	}

	getUsers() {
		const params = {
			company_id: this.company_id,
			user_type: this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.getUser, params).subscribe(data => {
				this.isLoading = false;
				if (data['code'] == 200) {
					if (data['data']) {
						let userData = data['data']['enterpriseList'];
						let user = userData.find((element) => element.enterprise_user_id == this.userId);
						if (user) {
							let userName = user.user_name;
							this.userForm.controls.user_name.setValue(userName);
							this.userForm.controls.email.setValue(user.email);
							this.userForm.controls.email.disable();
							this.userForm.controls.phone_no.setValue(user.mobile_no);
							this.userForm.controls.office_address.setValue(user.company_address_id);
							this.userForm.controls.designation.setValue(user.designation);
							this.selectedOfficeAddress = user.company_address_id;
							this.companyCategoryId = user.accessright;
							this.is_active = user.is_active;
							this.is_admin = user.is_admin_user;
							this.toggleAdmin()
						}
					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message'], 'top');
			})
		)
	}

	getCompanyAddressList() {
		const params = {
			company_id: this.company_id,
			company_for: this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller,
		}

		this.subscription.add(
			this.dataService.get(URLS.getAddressList, params).subscribe(data => {
				if (data['code'] == 200) {
					let addressList = data['data']['addressList'];
					let addressData = [];
					if (addressList && addressList.length) {
						addressList.forEach(element => {
							let obj = {
								name: element.address,
								id: element.company_address_id
							}
							addressData.push(obj);
						})
						this.officeAddress = addressData
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}

	onCancel() {
		this.navCtrl.navigateBack("/home/account-details/manage-user");
	}

	onSubmit() {

		if (this.isLoading == true) {
			return;
		}
		for (const i in this.userForm.controls) {
			this.userForm.controls[i].markAsDirty();
			this.userForm.controls[i].updateValueAndValidity();
			this.userForm.controls[i].markAsTouched();
		}

		if (this.categoriesData.categoriesId.length < 1) {
			this.categoryNotChecked = true;
			this.commonService.scrollToTop();
			return;
		} else {
			this.categoryNotChecked = false;
		}
		if (this.userForm.invalid) {
			this.commonService.scrollToTop();
			return;
		}

		let addUser: AddUser = {};
		let access_rights_array: any = []
		this.categoriesData.categoriesId.forEach(element => {
			if (element.category_id) {
				access_rights_array.push(element.category_id)
			}
		});
		if (access_rights_array.length == 0 && !this.is_admin) {
			this.categoryNotChecked = true
			return;
		}
		addUser.user_name = this.userForm.value.user_name;
		if (!this.userId) {
			addUser.email = this.userForm.value.email;
		}
		addUser.mobile_no = this.userForm.value.phone_no;
		addUser.is_active = this.is_active;
		addUser.is_admin_user = this.is_admin;
		addUser.office_address_id = this.selectedOfficeAddress;
		addUser.access_rights = Array.from(new Set(access_rights_array));
		addUser.designation = this.userForm.value.designation;
		addUser.user_type = this.commonService.userData && this.commonService.userData['isCustomer'] ? this.profileEnum.Customer : this.profileEnum.Seller;

		if (this.userId) {
			addUser.enterprise_user_id = +this.userId;
		}
		let encryptedData = this.commonService.encryptData(JSON.stringify(addUser));
		let param = {
			data: encryptedData
		}

		this.isLoading = true;
		if (this.userId) {
			this.subscription.add(
				this.dataService.put(URLS.editUser, addUser).subscribe(data => {
					if (data['code'] == 200) {
						this.getCompanyDetail().then((data) => {
							if (data) {
								this.getUsers();
								this.navCtrl.navigateBack('/home/account-details/manage-user');
								this.toasterService.successToast(data['message'], 'top');
							}
						});
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message'], 'top');
				})
			)
		}
		else {
			this.subscription.add(
				this.dataService.post(URLS.addUser, param).subscribe(data => {
					if (data['code'] == 200) {
						this.userForm.reset();
						this.categoriesData.categoriesList.forEach(item => item.checked = false);
						this.commonService.scrollToTop();
						this.navCtrl.navigateBack('/home/account-details/manage-user');
						this.toasterService.successToast(data['message'], 'top');
					}
					else {
						this.toasterService.errorToast(data['message'], 'top');
					}
					this.isLoading = false;
				}, error => {
					this.isLoading = false;
					this.toasterService.errorToast(error['message'], 'top');
				})
			)
		}
	}

	tabChange() {
		let param = {
			"enterprise_user_id": this.userId
		}
		if (this.UserToggle == 'quotes') {
			this.getQuoteList();
		}
	}

	filterShowButton() {
		this.showFilter = !this.showFilter;
	}

	onDateChange(datetime) {
		// datetime.cancel(true);
	}

	// search text parameter in api
	search() {
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return;
		}
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
			this.getQuoteList();
		}
	}
	// search text parameter in api
	onSearch() {
		this.searchObj = {}
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
		}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toasterService.errorToast("Please select From Date");
			}
			if (!this.toDate) {
				this.toasterService.errorToast("Please select To Date");
			}
			return
		}

		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		this.currentPage = 1;
		this.pageNo = 1;

		if (Object.keys(this.searchObj).length > 0) this.getQuoteList();

	}

	closeFilter() {
		this.searchObj = {}
		this.searchText = null;
		this.fromDate = null;
		this.toDate = null;
		this.pageNo = 1;
		this.pagesize = 10;
		this.currentPage = 1;
		this.getQuoteList();
	}

	getQuoteList() {
		let param = {}
		param['page'] = this.pageNo;
		param['limit'] = this.pagesize;
		param['enterprise_user_id'] = this.userId;
		param['enterprise_user_type'] = this.commonService.userData.isCustomer ? 'CUSTOMER' : 'SELLER';
		if (this.searchObj) {
			param = {
				...param,
				...this.searchObj
			}
		}
		this.isQuoteList = true;
		this.subscription.add(
			this.dataService.get(URLS.userQuotesRequest, param).subscribe(data => {
				this.isQuoteList = false;
				if (data['code'] == 200) {
					this.listOfAllData = data['data']['manageQuoteList'];
					this.totalCount = data['data']['total_count'];
					this.listOfAllData.forEach((element, index) => {
						element['id'] = element['quote_id'];
						element['index'] = (this.pageNo - 1) * this.pagesize + index + 1;
						element['request_on'] = this.datePipe.transform(element["created_at"], 'dd-MM-yyyy h:mm a');
						element['product_name'] = element['product_name'];
						element['img'] = element['product_image'];
						element['qty'] = element['quantity'];
						element['timeframe'] = element['timeframe'] + " hours";
						if (element['total_price']) element['quotation'] = this.commonService.currencySymbol + " " + element['total_price'];
						element['status_type'] = element['status'] == QuoteStatus.SUBMITTED ? 'green-light-box' :
							element['status'] == QuoteStatus.REQUESTED ? 'blue-light-box' :
								element['status'] == QuoteStatus.RECEIVED ? 'red-light-box' :
									element['status'] == QuoteStatus.ORDER_INITIATED ? 'orange-light-box' :
										element['status'] == QuoteStatus.CLOSED ? 'grey-light-box' : 'red-light-box';
						element['view'] = true;
						element["viewLink"] = '/home/account-details/manage-user/user-quote-detail';
						element['status'] = element['status'].toLowerCase();
						this.commonService.localStorageSet("enterprise_user_id", this.userId)
					});
				}
				else {
					this.isQuoteList = false;
					this.toasterService.errorToast(data['message'], 'top');
				}
				this.isLoading = false;
			}, error => {
				this.isQuoteList = false;
				this.isLoading = false;
				this.toasterService.errorToast(error['message'], 'top');
			})
		)
	}

	onChange(event): void {
		this.currentPage = event;
		this.pageNo = this.currentPage;
		this.getQuoteList()
	}

	changeListItem() {
		this.currentPage = 1;
		this.pageNo = 1;
		this.pagesize = this.paginationVal;
		this.itemsPerPage = this.paginationVal
		this.getQuoteList();
	}


	toggleAdmin() {
		if (this.is_admin) {
			this.categoriesData.selectAllCategories();
		} else {
			this.categoriesData.deselectAllCategories();

		}
	}
	ngOnDestroy() {
	}
}

