import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { ToasterService } from './../../services/toaster.service';


export interface CommpanyAddress {
	office?: Headquarter[];
	dispatch?: Headquarter[];
	OTHER?: Headquarter[];
}

export interface Headquarter {
	company_address_id?: number;
	company_id?: number;
	company_name?: string;
	address_type?: null | string;
	address?: string;
	dispatch_name?: null;
	address_type_id?: number | null;
	country_id?: number;
	state_id?: number;
	city_id?: number;
	zip_code?: string;
	gst_number?: null | string;
	country_code?: null;
	contact_no?: null | string;
}

@Component({
	selector: 'app-seller-address',
	templateUrl: './seller-address.component.html',
	styleUrls: ['./seller-address.component.scss'],
})
export class SellerAddressComponent implements OnInit {
	public subscription = new Subscription();
	isSellerApproval = false;
	isLoading = true;
	companyAddressList: CommpanyAddress = {};
	addressList = [
		{
			"heading": 'Company Main Address',
			"title": "Company Main Address",
			"address_label": "Headquarters",
			"company_name": "Maecenas max ",
			"address": "E 701 Lotus Corporate Park, Laxmi Nagar, Western Express Highway, Goregaon (E), Mumbai 400063, India"

		},
		{
			"heading": 'Company Address 2',
			"title": "Company Address 2",
			"address_label": "Other office",
			"company_name": "Maecenas max ",
			"address": "E 701 Lotus Corporate Park, Laxmi Nagar, Western Express Highway, Goregaon (E), Mumbai 400063, India"

		},
	];
	dispatchList = [
		{
			"heading": 'Company Main Address',
			"title": "dispatch Main Address",
			"address_label": "dispatch 1",
			"company_name": "Maecenas max ",
			"address": "E 701 Lotus Corporate Park, Laxmi Nagar, Western Express Highway, Goregaon (E), Mumbai 400063, India"

		},
		{
			"heading": 'Company Address 2',
			"title": "dispatch Main Address 2",
			"address_label": "dispatch 2",
			"company_name": "Maecenas max ",
			"address": "E 701 Lotus Corporate Park, Laxmi Nagar, Western Express Highway, Goregaon (E), Mumbai 400063, India"

		},
	]
	@Input() progress = 0;
	isSubUser = false;
	isSubUserAdmin = false;

	constructor(
		private router: Router,
		private commonService: CommonService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		public alertCtrl: AlertController,
		public navCtrl: NavController
	) {
		this.isSubUser = this.commonService.localStorageGet('is_sub_user') == 'true' ? true : false;
		this.isSubUserAdmin = this.commonService.localStorageGet('is_sub_user_admin') == 'true' ? true : false;
	}

	ngOnInit() {
		this.commonService.scrollToTop();
		this.getCompanyAddress();
		this.getProgressBar();
	}

	getCompanyAddress() {
		this.isLoading = true;
		this.companyAddressList.dispatch = [];
		this.companyAddressList.office = [];
		this.subscription.add(
			this.dataService.get(URLS.sellerAddressDetail).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						let addressData: any = {}
						addressData = data['data'];
						let office = addressData['OFFICE'] ? addressData['OFFICE'] : [];
						let headquater = addressData['HEADQUARTER'] ? addressData['HEADQUARTER'] : [];
						let other = addressData['OTHER'] ? addressData['OTHER'] : [];
						this.companyAddressList.dispatch = addressData['DISPATCH LOCATION'] ? addressData['DISPATCH LOCATION'] : [];
						this.companyAddressList.office = [...headquater, ...office, ...other];
					}
					this.isLoading = false;
				}
				else {
					this.companyAddressList.dispatch = [];
					this.companyAddressList.office = [];
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.companyAddressList.dispatch = [];
				this.companyAddressList.office = [];
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	getProgressBar() {
		this.subscription.add(
			this.dataService.get(URLS.sellerProgressBar).subscribe(data => {
				if (data['code'] == 200) {
					this.progress = data['data']['progress'];
					let localSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
					let is_sub_user = false;
					is_sub_user = this.commonService.localStorageGet('is_sub_user') == 'true' ? true : false;
					if (localSellerApproval && this.progress == 100 && !is_sub_user) {
						this.isSellerApproval = true;
					}
					else if (is_sub_user) {
						this.isSellerApproval = true;
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
			}, error => {
				this.toasterService.errorToast(error['message']);
			})
		)
	}
	async deleteConfrm(id) {
		const alert = await this.alertCtrl.create({
			header: 'Are you sure ? ',
			message: 'You want to delete this address ?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'Yes',
					role: 'confirm',
					handler: () => {
						this.deleteAddress(id);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();

	}

	deleteAddress(id) {
		let param = {
			"company_address_id": id
		}
		this.subscription.add(
			this.dataService.delete(URLS.sellerAddressDelete, param).subscribe(data => {
				if (data['code'] == 200) {
					this.getCompanyAddress();
					this.toasterService.successToast(data['message']);
				}
				else {
					this.toasterService.errorToast(data['message']);
				}

			}, error => {
				this.toasterService.errorToast(error['message']);

			})
		)

	}

	editAddress(id) {
		this.navCtrl.navigateForward(['/home/account-details/manage-company-detail/edit-company-address', id])
	}

	addSellerAddress() {
		this.navCtrl.navigateForward(['/home/account-details/manage-company-detail/add-company-address'])
	}



}
