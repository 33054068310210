import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import * as lodash from 'lodash';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/_common/customvalidators';
import { URLS } from 'src/app/_config/api.config';
import { LocationService } from 'src/app/services/location.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { MasterApiService } from '../../services/master-api.service';
import { ToasterService } from '../../services/toaster.service';

// IMAGE FILE SIZE 40mb MAX
const MAX_FILE_SIZE_IMAGE = 5 * 1024 * 1024;

@Component({
	selector: 'app-add-edit-vehicle',
	templateUrl: './add-edit-vehicle.component.html',
	styleUrls: ['./add-edit-vehicle.component.scss'],
})
export class AddEditVehicleComponent implements OnInit {
	@Input() fromProfile?: any;
	public subscription = new Subscription();
	isLoading = false;
	dataId: any;
	isView: boolean = false
	addEditVehicleForm: FormGroup;
	public validation_messages = {
		vehicle_name: [
			{ type: 'required', message: 'Please enter vehicle name' },
			{ type: 'invalidString', message: 'Please enter valid name' },
			{ type: 'invalidName', message: 'Please enter valid name' },
			{ type: 'maxlength', message: 'Vehicle name should not be more than 50 characters long' }
		],
		vehicle_type: [
			{ type: 'required', message: 'Please select vehicle type' },
		],
		description: [
			{ type: 'invalidString', message: 'Please enter valid description' },
			{ type: 'invalidName', message: 'Please enter valid description' },
			{ type: 'maxlength', message: 'Description name should not be more than 500 characters long' }
		],
		category: [
			{ type: 'required', message: 'Please select category' },
		],
		unit: [
			{ type: 'required', message: 'Please select unit' },
		],
		minimum_capacity: [
			{ type: 'required', message: 'Please enter minimum capacity' },
			{ type: 'maxlength', message: 'Minimum capacity should not be more than 7 characters long' },
			{ type: 'invalidNumber', message: 'Enter valid minimum capacity' },
			{ type: 'pattern', message: 'Enter valid minimum capacity' },
			{ type: 'greaterThanZero', message: 'Enter valid minimum capacity' },
		],
		maximum_capacity: [
			{ type: 'required', message: 'Please enter maximum capacity' },
			{ type: 'maxlength', message: 'Maximum capacity should not be more than 7 characters long' },
			{ type: 'invalidNumber', message: 'Enter valid maximum capacity' },
			{ type: 'pattern', message: 'Enter valid maximum capacity' },
			{ type: 'greaterThanZero', message: 'Enter valid maximum capacity' },
		],
		standard_price: [
			{ type: 'required', message: 'Please enter standard per km rate' },
			{ type: 'invalidNumber', message: 'Enter valid standard per km rate' },
			{ type: 'pattern', message: 'Enter valid standard per km rate' },
			{ type: 'greaterThanZero', message: 'Enter valid standard per km rate' },
		],
		vehicle_no: [
			{ type: 'required', message: 'Please enter vehicle registration plate number' },
			{ type: 'invalidVehicleNumber', message: 'Enter valid vehicle registration plate number' },
		],
	};

	vehicleType = [];
	categoryList = [];
	unitList = [];
	regionList = [];
	isEditLoading = false;

	isDisabled = false;
	imageChangedEvent: any = '';
	imageFile: any = '';
	vehicleImg: any;
	selectedRegionList: any = [];
	previousRegionList: any = [];
	changedRegionForTaluka: any = [];
	@Input() isSellerApproval = false;
	@Input() progress: any;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		private masterApi: MasterApiService,
		private dataService: ApiService,
		private toasterService: ToasterService,
		private navCtrl: NavController,
		private route: ActivatedRoute,
		private locationService: LocationService

	) { }

	ngOnInit() {
		this.commonService.scrollToTop();
		let localSellerApproval = this.commonService.localStorageGet('seller_approval') == 'PENDING' ? true : false;
		let is_sub_user = false;
		let isSub_user_Admin = false;
		is_sub_user = this.commonService.localStorageGet('is_sub_user') == 'true' ? true : false;
		isSub_user_Admin = this.commonService.localStorageGet('is_sub_user_admin') == 'true' ? true : false;
		if (localSellerApproval && this.progress == 100 && !is_sub_user) {
			this.isSellerApproval = true; // disable
			this.isDisabled = true; // disable
			this.commonService.setEvent('isProgessCall', true)
		}

		this.route.paramMap.subscribe((params: ParamMap) => {
			this.dataId = params.get('id');
		});
		this.route.queryParams
			.subscribe(params => {
				if (params.isView) {
					this.isView = params.isView
				}
			}
			);

		let promiseArray = [
			this.getVehicleTypeList(),
			this.getRegionList()
		];
		this.isLoading = true;
		Promise.all(promiseArray).then(data => {
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});

		if (!this.dataId) {
			this.addEditVehicleForm = this.initializeForm();
		}
		else {
			this.getVehicleDetails()
		}

		if (this.fromProfile) {
			this.getVehicleList();
		}

	}

	get f1() { return this.addEditVehicleForm.controls; }

	initializeForm(data?) {
		return this.formBuilder.group({
			vehicle_name: new FormControl({ value: data && data.vehicle_name ? data.vehicle_name : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.charnumberValidator, CustomValidator.noWhiteSpaceValidator]),
			}),
			vehicle_type: new FormControl({ value: data && data.vehicle_type_id ? data.vehicle_type_id : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.required]),
			}),
			description: new FormControl({ value: data && data.description ? data.description : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.maxLength(500), CustomValidator.noWhiteSpaceValidator]),
			}),
			category: new FormControl({ value: data && data.vehicle_name ? data.vehicle_name : '', disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.required]),
			}),
			unit: new FormControl({ value: data && data.capacity_unit_id ? data.capacity_unit_id : null, disabled: true }, {
				validators: Validators.compose([Validators.required]),
			}),
			minimum_capacity: new FormControl({ value: data && data.minimum_capacity ? data.minimum_capacity : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(7), Validators.pattern(/^\d+(\.\d+)?$/), CustomValidator.greaterThanZeroValidator]),
			}),
			maximum_capacity: new FormControl({ value: data && data.maximum_capacity ? data.maximum_capacity : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(7), Validators.pattern(/^\d+(\.\d+)?$/), CustomValidator.greaterThanZeroValidator]),
			}),
			standard_price: new FormControl({ value: data && data.standard_per_km_price ? data.standard_per_km_price : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern(/^\d+(\.\d+)?$/), CustomValidator.greaterEqualThanZeroValidator]),
			}),
			vehicle_no: new FormControl({ value: data && data.registration_plate_number ? data.registration_plate_number : null, disabled: this.isDisabled == true ? true : false }, {
				validators: Validators.compose([CustomValidator.vehiclenumberValidator]),
			}),

		})
	}

	vehicleTypeChange(event) {
		setTimeout(() => {
			this.vehicleType.forEach(element => {
				if (element.vehicle_type_id == event.vehicle_type_id) {
					this.categoryList = element.categories;
					let categorySelect = this.categoryList.map(category => category.category_id);
					this.f1.category.setValue(categorySelect);
					this.categoryChange(this.categoryList[0]);
				}
			});
		}, 200)
	}

	categoryChange(event) {
		this.unitList = [];
		this.unitList.push(this.categoryList[0]);
		this.f1.unit.setValue(this.unitList[0].unit_id);
	}

	unitChange(event) {
		this.f1.unit.setValue(this.unitList[0].unit_id);
	}

	addVehicle() {
		for (const i in this.f1) {
			this.f1[i].markAsDirty();
			this.f1[i].updateValueAndValidity();
			this.f1[i].markAsTouched();
		}
		if (this.addEditVehicleForm.invalid) {
			this.commonService.scrollToTop();
			return;
		}

		let vehicle_region_pricing: any = [];

		if (this.selectedRegionList && this.selectedRegionList.length > 0) {
			let errorMsg: any;
			this.selectedRegionList.forEach(element => {
				if (!element.region_id) {
					errorMsg = "Please select Region";
				}
				else if (!element.per_km_price && element.is_region_level) {
					errorMsg = "Please add price per km for region";
				}
				else if (!element.is_region_level && !element.selectedTalukaList) {
					errorMsg = "Please select Taluka";
				}
				else if (element.is_region_level == false && element.selectedTalukaList && element.selectedTalukaList.length > 0) {
					let priceAdded;
					if (element.selectedTalukaList && element.selectedTalukaList.length > 0) {
						element.selectedTalukaList.forEach(element => {
							if (!element.per_km_price) {
								priceAdded = false;
							}
						});
					}

					if (priceAdded == false) {
						errorMsg = "Please add price for selected taluka";
					}

				}
			});

			if (errorMsg) {
				this.toasterService.errorToast(errorMsg);
				return;
			}

			this.selectedRegionList.forEach(element => {
				let obj: any = {};
				if (!element.is_region_level) {
					let talukaList: any = [];
					element.selectedTalukaList.forEach(element => {
						talukaList.push({ taluka_id: element.taluka_id, per_km_price: parseFloat(element.per_km_price) })
					});
					obj = {
						region_id: element.region_id,
						is_region_level: element.is_region_level == 1 ? true : false,
						taluka_price: talukaList
					}
				}
				else {
					obj = {
						region_id: element.region_id,
						is_region_level: element.is_region_level == 1 ? true : false,
						per_km_price: parseFloat(element.per_km_price)
					}
				}

				vehicle_region_pricing.push(obj);
			});
		}

		if (this.selectedRegionList && this.selectedRegionList.length > 1) {
			let duplicate = this.findDuplicate(this.selectedRegionList, 'region_id');

			if (duplicate == true) {
				this.toasterService.errorToast("Selected Region already exists");
				return;
			}
		}

		if (this.f1.minimum_capacity.value > this.f1.maximum_capacity.value) {
			this.toasterService.errorToast("Please enter valid capacity values");
			return;
		}



		let params = new FormData();

		params.append('vehicle_name', this.f1.vehicle_name.value);
		if (this.f1.description.value) params.append('description', this.f1.description.value);
		params.append('vehicle_type_id', this.f1.vehicle_type.value);
		params.append('minimum_capacity', this.f1.minimum_capacity.value);
		params.append('maximum_capacity', this.f1.maximum_capacity.value);
		params.append('capacity_unit_id', this.f1.unit.value);
		params.append('standard_per_km_price', this.f1.standard_price.value);
		params.append('registration_plate_number', this.f1.vehicle_no.value ? this.f1.vehicle_no.value : '');
		if (this.imageFile) params.append('vehical_image', this.imageFile ? this.imageFile : null);
		if (!this.dataId && vehicle_region_pricing.length) params.append('vehicle_region_pricing', JSON.stringify(vehicle_region_pricing));


		if (this.dataId && vehicle_region_pricing.length) {
			let previousRegionListArray = this.previousRegionList && this.previousRegionList.length ? JSON.parse(this.previousRegionList) : [];
			let prev_vehicle_region_pricing: any = [];
			previousRegionListArray.forEach(element => {
				let obj: any = {};
				if (!element.is_region_level) {
					let talukaList: any = [];
					element.selectedTalukaList.forEach(element => {
						talukaList.push({ taluka_id: element.taluka_id, per_km_price: element.per_km_price })
					});
					obj = {
						region_id: element.region_id,
						is_region_level: element.is_region_level,
						taluka_price: talukaList
					}
				}
				else {
					obj = {
						region_id: element.region_id,
						is_region_level: element.is_region_level,
						per_km_price: element.per_km_price
					}
				}

				prev_vehicle_region_pricing.push(obj);
			});


			let addedRegion = [];
			let removedRegion = [];
			let removedRegionId = [];
			let updatedRegion = [];


			if (vehicle_region_pricing.length > 0 || prev_vehicle_region_pricing > 0) {
				addedRegion = lodash.differenceBy(vehicle_region_pricing, prev_vehicle_region_pricing, "region_id");
				removedRegion = lodash.differenceBy(prev_vehicle_region_pricing, vehicle_region_pricing, "region_id");
				removedRegionId = removedRegion.map(region => region.region_id);
			}

			vehicle_region_pricing.forEach(element => {
				this.changedRegionForTaluka.forEach(element2 => {
					if (element.region_id == element2) {
						updatedRegion.push(element);
					}
				});
			});

			if (addedRegion.length) params.append('added', JSON.stringify(addedRegion));
			if (removedRegionId.length) params.append('deleted', JSON.stringify(removedRegionId));
			if (updatedRegion.length) params.append('edited', JSON.stringify(updatedRegion));
		}

		if (this.dataId) {
			params.append('vehicle_id', this.dataId);
			this.subscription.add(
				this.dataService.put(URLS.vehicleUpdate, params).subscribe(result => {
					if (result['code'] == 200) {
						this.cancel();
					}
					else {
						this.toasterService.errorToast(result['message']);
					}
				}, error => {
					this.toasterService.errorToast(error['message']);
				})
			)
		}
		else {
			this.subscription.add(
				this.dataService.post(URLS.vehicleCreate, params).subscribe(result => {
					if (result['code'] == 200) {
						if (this.fromProfile) {
							this.toasterService.successToast(result['message']);
							this.getVehicleList();
							this.commonService.localStorageSet("tabval", "5")
							setTimeout(() => {
								this.navCtrl.navigateBack(['/home/account-details/manage-company-detail'])
							}, 300)

						} else {
							this.cancel();
						}
					}
					else {
						this.toasterService.errorToast(result['message']);
					}
				}, error => {
					this.toasterService.errorToast(error['message']);
				})
			)
		}


	}

	cancel() {
		if (this.fromProfile) {
			this.addEditVehicleForm.reset();
			this.selectedRegionList = [];
			this.imageFile = null;
			this.vehicleImg = null;
		} else {
			this.navCtrl.navigateBack(['/home/account-details/manage-vehicles']);
		}
	}

	// Method to prevent form submission on Enter key press
	preventSubmit(event: KeyboardEvent): void {
		event.preventDefault();
	}

	// * MANUALLY TRIGGER CLICK EVENT
	// */
	public callInput(): void {
		let element: HTMLElement = document.getElementById('fileInput') as HTMLElement;
		element.click();
	}

	/**
   * FILE CHANGE EVENT FOR SELECTING IMAGES
   * @param event EVENT OBJECT
   */
	public fileChangeEvent(event: any): void {
		let fileObj = event.target.files[0];
		let fileExtension = fileObj['name'].split('.').pop().toLocaleLowerCase();
		let fileSize = fileObj['size'];
		const reader = new FileReader();
		if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
			if (fileSize < MAX_FILE_SIZE_IMAGE) {
				this.imageChangedEvent = event;
				this.imageFile = event['target']['files'][0];
				reader.readAsDataURL(event['target']['files'][0]);
				reader.onload = () => {
					this.vehicleImg = reader.result;
				};
			} else {
				this.toasterService.errorToast("The selected image has exceeded the maximum allowed size of 5 Mb.", 'top');
			}
		} else {
			this.toasterService.errorToast("Please select JPG, JPEG and PNG format image!", 'top');
		}
	}

	isDiffToggle(data, index) {
		if (data && !data.region_id) {
			this.toasterService.errorToast("please select a region");
			data.is_region_level = !data.is_region_level;
			return;
		}

		if (this.dataId) {
			this.changedRegionForTaluka.push(data.region_id);
		}
		data.is_region_level = !data.is_region_level;
		this.selectedRegionList[index].is_region_level = !this.selectedRegionList[index].is_region_level;
		this.selectedRegionList = [...this.selectedRegionList];

		this.regionList.forEach(element => {
			if (element.district_id == data.region_id) {
				data.region = element.district;
			}
		});

		if (this.dataId) {
			this.changedRegionForTaluka.push(data.region_id);
		}

		if (data.is_region_level == false) {
			let param = {
				"district_id": data.region_id,
				"area_covered": 1
			};
			this.subscription.add(
				this.dataService.get(URLS.taluka, param).subscribe(result => {
					if (result['code'] == 200) {
						data.talukaList = result['data']['talukaList'];
					}
					else {
						data.talukaList = [];
						this.toasterService.errorToast(result['message']);
					}
				}, error => {
					data.talukaList = [];
					this.toasterService.errorToast(error['message']);

				})
			)
		}
	}

	regionChange(data) {

		if (this.selectedRegionList.length > 1) {
			let duplicate = this.findDuplicate(this.selectedRegionList, 'region_id');

			if (duplicate == true) {
				this.toasterService.errorToast("Selected Region already exists");
				return;
			}
		}

		this.regionList.forEach(element => {
			if (element.district_id == data.region_id) {
				data.region = element.district;
			}
		});
		let param = {
			"district_id": data.region_id,
			"area_covered": 1
		};
		this.subscription.add(
			this.dataService.get(URLS.taluka, param).subscribe(result => {
				if (result['code'] == 200) {
					data.is_region_level = true
					data.per_km_price = null;
					data.selectedTalukaList = null;
					data.selectedTalukaListId = null;
					data.talukaList = result['data']['talukaList'];
				}
				else {
					data.talukaList = [];
					this.toasterService.errorToast(result['message']);
				}
			}, error => {
				data.talukaList = [];
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	regionPriceChange(data) {
		if (data && !this.changedRegionForTaluka.includes(data.region_id)) {
			this.changedRegionForTaluka.push(data.region_id);
		}
	}
	openRegion(data, index) {
		if (!data.region_id && data.is_region_level == true && !data.per_km_price) {
			this.selectedRegionList.splice(index, 1);
		}
		else {
			data.isRegionOpen = !data.isRegionOpen;

		}
	}

	deleteRegion(data, index) {
		this.selectedRegionList.splice(index, 1);
	}

	getVehicleTypeList() {
		let param = {
			"is_active": 1,
		};
		this.subscription.add(
			this.dataService.get(URLS.vehicleTypeList, param).subscribe(data => {
				if (data['code'] == 200) {
					this.vehicleType = data['data']['vehicleTypeList']
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	getRegionList() {
		let param = {
			"is_active": 1,
		};

		this.subscription.add(
			this.dataService.get(URLS.district_city, param).subscribe(data => {
				if (data['code'] == 200) {
					this.regionList = data['data']['districtList'];
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}


	findDuplicate(array, key) {
		let duplicate = false;
		for (let i = 0; i < array.length; i++) {
			for (let j = i + 1; j < array.length; j++) {
				if (array[i][key] == array[j][key]) {
					duplicate = true;
				}
			}
		}
		return duplicate;
	}
	addRegion() {
		if (this.selectedRegionList.length > 19) {
			this.toasterService.errorToast("Cannot add more than 20 regions");
			return;
		}
		if (this.selectedRegionList.length == 0) {
			this.selectedRegionList.push({ isRegionOpen: true, is_region_level: true, region_id: null, per_km_price: null });
		}
		else if (this.selectedRegionList.length > 0) {
			if (this.selectedRegionList.length > 1) {
				let duplicate = this.findDuplicate(this.selectedRegionList, 'region_id');

				if (duplicate == true) {
					this.toasterService.errorToast("Selected Region already exists");
					return;
				}
			}
			if (this.selectedRegionList.length > 0) {
				let errorMsg;
				this.selectedRegionList.forEach(element => {
					if (!element.region_id) {
						errorMsg = "Please select Region"

					}
					else if (!element.per_km_price && element.is_region_level) {
						errorMsg = "Please add price per km for region";
					}
					else if (!element.is_region_level && !element.selectedTalukaList) {
						errorMsg = "Please select Taluka";
					}
					else if (element.is_region_level == false && element.region_id) {
						let priceAdded;
						if (element.selectedTalukaList && element.selectedTalukaList.length > 0) {
							element.selectedTalukaList.forEach(element => {
								if (!element.per_km_price) {
									priceAdded = false;
								}
							});
						}

						if (priceAdded == false) {
							errorMsg = "Please add price for selected taluka";
						}

					}
				});

				if (errorMsg) {
					this.toasterService.errorToast(errorMsg);
					return;
				}
				else {
					this.selectedRegionList.push({ isRegionOpen: true, is_region_level: true, region_id: null, per_km_price: null });
				}
			}

		}
	}


	quantityInput(event, data) {
		// Get the input value
		let inputValue = event.target.value;
		if (inputValue === 'e' || inputValue === '+' || inputValue === '-' || inputValue == '/' || inputValue == '_' || inputValue == ',') {
			return event.target.value = 1;
		}
		// Remove '.' and ',' characters from the input value
		inputValue = inputValue.replace(/[^a-zA-Z0-9.]/g, '');

		// Update the ngModel-bound variable
		data.per_km_price = inputValue;
		this.priceValidation(data);

	}
	quantityInputBlur(event, data) {
		// Get the input value
		let inputValue = event.target.value;
		if (inputValue === 'e' || inputValue === '+' || inputValue === '-' || inputValue == '/' || inputValue == '_' || inputValue == ',') {
			return event.target.value = 1;
		}
		// Remove '.' and ',' characters from the input value
		inputValue = inputValue.replace(/[^a-zA-Z0-9.]/g, '');

		// Update the ngModel-bound variable
		data.per_km_price = inputValue;
		this.priceValidation(data);
	}

	priceChangeValidation(itemRow) {
		if (itemRow && !this.changedRegionForTaluka.includes(itemRow.region_id)) {
			this.changedRegionForTaluka.push(itemRow.region_id);
		}
	}
	priceValidation(data) {

		if (data.per_km_price == null || !data.per_km_price || data.per_km_price == '') {
			data['isPriceValid'] = true;
			data['isNegativePrice'] = false;
		}
		else if (data.per_km_price <= 0) {
			data['isNegativePrice'] = true;
			data['isPriceValid'] = false;
		} else {
			data['isPriceValid'] = false;
			data['isNegativePrice'] = false;
		}
	}

	isnumeric(e) {
		if (e.keyCode == 46) {

		}
		else if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}


	selectedTalukaChange(event, data) {
		let currentTaluka = event;
		if (data.selectedTalukaList) {
			currentTaluka.forEach(element => {
				data.selectedTalukaList.forEach(element2 => {
					if (element2.taluka_id == element.taluka_id) {
						element.per_km_price = element2.per_km_price ? element2.per_km_price : null;
					}
				});
			});

		}

		data.selectedTalukaList = currentTaluka;


		if (!this.changedRegionForTaluka.includes(data.region_id)) {
			this.changedRegionForTaluka.push(data.region_id);
		}
	}


	getVehicleDetails() {
		let param = {
			"vehicle_id": this.dataId,
		};
		this.isEditLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.vehicleDetail, param).subscribe(async data => {
				if (data['code'] == 200) {
					let result = data['data'][0];
					this.vehicleImg = result.vehicle_images && result.vehicle_images.length ? result.vehicle_images[0]['image_url'] : null;
					this.selectedRegionList = [];
					this.addEditVehicleForm = this.initializeForm(result);

					await this.vehicleTypeChange(result);

					if (result.vehicle_region_pricing && result.vehicle_region_pricing.length > 0) {
						result.vehicle_region_pricing.forEach(element => {
							let obj = {
								"isRegionOpen": true,
								"is_region_level": element.is_region_level,
								"region_id": element.region_id,
								"per_km_price": element.per_km_price,
								"region": element.region_name,
								"selectedTalukaList": element.taluka_data,
								"selectedTalukaListId": element.taluka_data && element.taluka_data.length > 0 ?
									element.taluka_data.map(taluka => +taluka.taluka_id) : null
							}
							this.getRegionViseTaluka(element).then(data => {
								obj['talukaList'] = data;
							}, error => {
								this.toasterService.errorToast(error.message);
							});

							setTimeout(() => {
								this.selectedRegionList.push(obj);
							}, 300)
						});

						setTimeout(() => {
							this.previousRegionList = JSON.stringify(this.selectedRegionList);
						}, 400)

					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isEditLoading = false;
			}, error => {
				this.isEditLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	getRegionViseTaluka(data) {
		return new Promise((resolve, reject) => {

			let param = {
				"district_id": data.region_id,
				"area_covered": 1
			};
			this.subscription.add(
				this.dataService.get(URLS.taluka, param).subscribe(result => {
					if (result['code'] == 200) {
						resolve(result['data']['talukaList']);
					}
					else {
						reject();
					}
				}, error => {
					reject();

				})
			)
		});
	}

	getVehicleList() {
		const params = {
			page: 1,
			limit: 1
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.vehicleList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.isLoading = false;
					if (data['data']) {
						let listData = data['data']['vehicleList'];
						if (listData && listData.length) {
							this.getVehicleDetailsForProfile(listData[0].vehicle_id);
						}
					}
				}
				else {
					this.toasterService.errorToast(data['message'], 'top');
				}
			}, error => {
				this.toasterService.errorToast(error['message'], 'top');
			})
		)
	}

	getVehicleDetailsForProfile(id) {
		let param = {
			"vehicle_id": id,
		};
		this.subscription.add(
			this.dataService.get(URLS.vehicleDetail, param).subscribe(async data => {
				if (data['code'] == 200) {
					this.isDisabled = true;
					let result = data['data'][0];
					this.vehicleImg = result.vehicle_images && result.vehicle_images.length ? result.vehicle_images[0]['image_url'] : null;
					this.selectedRegionList = [];
					this.addEditVehicleForm = this.initializeForm(result);

					await this.vehicleTypeChange(result);

					if (result.vehicle_region_pricing && result.vehicle_region_pricing.length > 0) {
						result.vehicle_region_pricing.forEach(element => {
							let obj = {
								"isRegionOpen": false,
								"is_region_level": element.is_region_level,
								"region_id": element.region_id,
								"per_km_price": element.per_km_price,
								"region": element.region_name,
								"selectedTalukaList": element.taluka_data,
								"selectedTalukaListId": element.taluka_data && element.taluka_data.length > 0 ?
									element.taluka_data.map(taluka => +taluka.taluka_id) : null
							}

							this.getRegionViseTaluka(element).then(data => {
								obj['talukaList'] = data;
							}, error => {
								this.toasterService.errorToast(error.message);
							});

							setTimeout(() => {
								this.selectedRegionList.push(obj);
							}, 300)
						});
					}
				}
				else {
					this.toasterService.errorToast(data['message']);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

}
